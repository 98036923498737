import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForbiddenComponent } from './modules/core/pages/error-pages/forbidden/forbidden.component';
import { NotFoundComponent } from './modules/core/pages/error-pages/not-found/not-found.component';
import { UnauthorizedComponent } from './modules/core/pages/error-pages/unauthorized/unauthorized.component';
import { SimplePageLayoutComponent } from './modules/core/layouts/simple-page-layout/simple-page-layout.component';
import { AppLayoutComponent } from './modules/core/layouts/app-layout/app-layout.component';
import { AuthGuard } from './modules/core/guards/auth.guard';
import { DashboardComponent } from './modules/core/pages/dashboard/dashboard.component';
import { BusinessAdminComponent } from './modules/core/pages/business-admin/business-admin.component';
import { OrgAdminComponent } from './modules/core/pages/org-admin/org-admin.component';
import { TeamMembersComponent } from './modules/core/pages/team-members/team-members.component';
import { AddOrgAdminComponent } from './modules/core/pages/org-admin/add-org-admin/add-org-admin.component';
import { EditOrgAdminComponent } from './modules/core/pages/org-admin/edit-org-admin/edit-org-admin.component';
import { AddTeamMemberComponent } from './modules/core/pages/team-members/add-team-member/add-team-member.component';
import { EditTeamMemberComponent } from './modules/core/pages/team-members/edit-team-member/edit-team-member.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
    },
    {
        path: 'app',
        component: AppLayoutComponent,
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'dashboard',
            },
            {
                path: 'user',
                loadChildren: () =>
                    import('./modules/user/user.module').then((m) => m.UserModule),
            },
            {
                path: 'admin',
                loadChildren: () =>
                    import('./modules/admin/admin.module').then((m) => m.AdminModule),
            },
            {
                path: 'dashboard',
                component: DashboardComponent,
            },
            {
                path: 'business-admin',
                component: BusinessAdminComponent,
            },
            {
                path: 'org-admin',
                children: [
                    {
                        path: '',
                        component: OrgAdminComponent
                    },
                    {
                        path: 'add',
                        component: AddOrgAdminComponent,
                    },
                    {
                        path: ':id/edit',
                        component: EditOrgAdminComponent,
                    },
                ]
            },  
            {
                path: 'team-member',
                children: [
                    {
                        path: '',
                        component: TeamMembersComponent
                    },
                    {
                        path: 'add',
                        component: AddTeamMemberComponent,
                    },
                    {
                        path: ':id/edit',
                        component: EditTeamMemberComponent,
                    },
                ]
            },
        ],
    },
    {
        path: '',
        component: SimplePageLayoutComponent,
        children: [
            {
                path: 'not-found',
                component: NotFoundComponent,
            },
            {
                path: 'forbidden',
                component: ForbiddenComponent,
            },
            {
                path: 'unauthorized',
                component: UnauthorizedComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
