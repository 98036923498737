import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Organization } from '../models/organization.model';

@Component({
    selector: 'app-org-admin-info',
    templateUrl: './org-admin-info.component.html',
    styleUrls: ['./org-admin-info.component.scss'],
})
export class OrgAdminInfoComponent implements OnInit {
  public form: FormGroup;
  @Input() organization: Organization | null;
  @Output() submitOrganization: EventEmitter<Organization> = new EventEmitter<Organization>();
  public orgPic: string;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      id: [null],
      logoImagePath: [null],
      name: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      ein: ['', [Validators.required]],
      emailAddress: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required]],
      description: ['', [Validators.required]],
      goal1: [''],
      goal2: [''],
      goal3: [''],
      address: ['', [Validators.required]],
      address2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip: ['', [Validators.required]],
    });

    if (this.organization) {
      this.form.patchValue(this.organization);
      this.orgPic = this.organization.logoImagePath;
    } else {
      this.form.patchValue(new Organization());
    }
  }

  public onSubmitOrganization(): void {
    if (!this.form.valid) return;

    const currentOrganization = new Organization(this.form.getRawValue());

    this.submitOrganization.emit(currentOrganization);
  }

  imageUploader(file: any) {
    const fr = new FileReader();

    fr.onload = (e: any) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const maxPixelLength = 400;
        let resizeRatio = 0;

        if (img.width <= maxPixelLength && img.height <= maxPixelLength) {
          resizeRatio = 1;
        } else if (img.width > img.height) {
          resizeRatio = img.width / maxPixelLength;
        } else {
          resizeRatio = img.height / maxPixelLength;
        }

        canvas.width = img.width / resizeRatio;
        canvas.height = img.height / resizeRatio;

        context.drawImage(img, 0, 0, canvas.width, canvas.height);
        this.form.controls['logoImagePath'].setValue(canvas.toDataURL());
        this.orgPic = canvas.toDataURL();
      };

      fr.readAsDataURL(file.files[0]);
    }
  }
}
