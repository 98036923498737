<form [formGroup]="form">
  <div class="d-flex flex-column justify-content-start">
    <input
      class="form-control mb-2 mapper-input"
      pInputText
      type="text"
      formControlName="name"
    />
    <ng-container *ngIf="mapping.headerRowIndex">
      <div class="d-flex align-items-center">
        <p-checkbox
          formControlName="hasHeaderRow"
          [binary]="true"
          inputId="hasHeaderRow"
        ></p-checkbox>
        <label class="ms-1" for="hasHeaderRow">Header Row</label>
        <input
          class="form-control ms-4 mapper-short-input"
          pInputText
          type="number"
          formControlName="headerRowIndex"
          [min]="0"
        />
      </div>
    </ng-container>
    <div formArrayName="columns" class="mb-4 mt-4">
      <div
        *ngFor="let column of columns.controls; let i = index"
        class="mb-2"
        [formGroupName]="i"
      >
        <div class="d-flex flex-wrap align-items-center">
          <p-dropdown
            class="mapper-input"
            formControlName="name"
            optionLabel="name"
            optionValue="name"
            optionDisabled="disabled"
            [options]="fileColumnOptions"
            [autoDisplayFirst]="false"
            [editable]="editableFileColumns"
            (onChange)="fileColumnSelect($event.value)"
          ></p-dropdown>
          <i class="pi pi-arrow-right ms-4"></i>
          <p-dropdown
            class="ms-4 mapper-input"
            formControlName="mappedTo"
            optionLabel="name"
            optionValue="name"
            optionDisabled="disabled"
            [options]="mappedToColumnOptions"
            [autoDisplayFirst]="false"
            (onChange)="mappedToColumnSelect($event.value)"
          ></p-dropdown>
          <p-checkbox
            class="ms-4"
            formControlName="required"
            [binary]="true"
            inputId="requiredCheck"
          ></p-checkbox>
          <label class="ms-1" for="requiredCheck">Required</label>
          <button
            type="button"
            pButton
            pRipple
            class="p-button-text p-button-rounded p-button-plain p-mr-2 ms-4"
            icon="bi bi-trash3-fill"
            title="Delete"
            pTooltip="Delete"
            (click)="removeMapping(i)"
          ></button>
        </div>
      </div>
    </div>
    <p-button
      label="Column"
      icon="pi pi-plus"
      (onClick)="addColumn()"
    ></p-button>
  </div>
</form>
<div class="mt-4 d-flex justify-content-end">
  <p-splitButton
    label="Preview"
    [model]="previewOptions"
    styleClass="p-button-text"
    (onClick)="onPreviewEmit()"
    [disabled]="!columns.controls.length"
  ></p-splitButton>
  <button
    cancelButton
    class="btn btn-secondary me-0 ms-2 mt-md-0"
    (click)="onCancelEmit()"
  >
    Cancel
  </button>
  <button
    type="submit"
    class="btn btn-primary me-0 ms-2 mt-md-0"
    [disabled]="form.invalid || form.pristine"
    (click)="onSaveEmit()"
  >
    Save
  </button>
</div>
