import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserRole } from 'src/app/modules/shared/statuses';
import { User } from '../../models/user';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private _user: BehaviorSubject<User | null> =
        new BehaviorSubject<User | null>(null);

    get userSnapshot(): User | null {
        return this._user.value;
    }

    get user$(): Observable<User | null> {
        return this._user.asObservable();
    }

    constructor() {}

    public setUser(user: User | null) {
        this._user.next(user);
    }

    public getRole(): UserRole | null {
        if (this.userSnapshot?.roles.some((r) => r === UserRole.Admin)) {
            return UserRole.Admin;
        } else if (
            this.userSnapshot?.roles.some((r) => r === UserRole.InternalUser)
        ) {
            return UserRole.InternalUser;
        } else if (
            this.userSnapshot?.roles.some((r) => r === UserRole.ExternalUser)
        ) {
            return UserRole.ExternalUser;
        } else return null;
    }

    public getUserName(): string {
        return this.userSnapshot?.userName ?? '';
    }
}
