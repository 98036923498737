import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { finalize, Observable, tap } from 'rxjs';
import { TeamMember } from '../models/team-member.model';
import { EditTeamMemberService } from '../services/edit-team-member.service';
import { OrgAdminApiService } from '../../org-admin/services/org-admin-api.service';
import { Organization } from '../../org-admin/models/organization.model';

@Component({
    selector: 'app-edit-team-member',
    templateUrl: './edit-team-member.component.html',
    styleUrls: ['./edit-team-member.component.scss'],
    providers: [EditTeamMemberService],
})
export class EditTeamMemberComponent implements OnInit {
    public teamMember$: Observable<TeamMember | null>;
    public loading: boolean = false;
    public organizationList: Organization[];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private editTeamMemberSvc: EditTeamMemberService,
        private orgAdminSvc: OrgAdminApiService,
    ) {}

    ngOnInit(): void {
        this.orgAdminSvc.getOrganizations().subscribe(o => {
            this.organizationList = o.data;
        });

        this.route.params.subscribe((params) => {
            this.teamMember$ = this.editTeamMemberSvc.teamMember$;
            
            const teamMemberId = params['id'];
            
            this.editTeamMemberSvc
                .initializeForTeamMember(teamMemberId)
                .subscribe();
        });
    }

    public onSubmit(event: any): void {
        const teamMember = event.teamMember;

        if (event.saveAndAdd) {
            this.loading = true;
            this.editTeamMemberSvc.save(teamMember).pipe(
                tap(() => this.router.navigate(['/app/team-member/add', {orgId: event.orgId}])),
                finalize(() => { this.loading = false; })).subscribe();
        } else {
            this.editTeamMemberSvc.save(teamMember).pipe(
                tap(() => this.router.navigateByUrl('/app/team-member'))).subscribe();
        }
    }
}
