import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { map, tap } from 'rxjs';
import { IDashboardStatistics, ITopThreeBusiness, ITopThreeOrganization } from '../../models/dashboard-statistics.interface';
import { Organization } from '../org-admin/models/organization.model';
import { Business } from '../business-admin/models/business.model';

const TOTAL_REVENUE_INDEX = 0;
const TOTAL_REVENUE_FOR_FLE_INDEX = 1;
const TOTAL_REVENUE_FOR_OTHER_ORGS_INDEX = 2;
const TOTAL_SUBSCRIBERS_INDEX = 0;
const SUBSCRIBERS_GAINED_OR_LOST_INDEX = 1;
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    public isLoading = true;
    public username: string;
    public dashboardStatistics: IDashboardStatistics = null;

    public topRowStats = [
        {
            title: 'Total Revenue',
            num: 0,
            numType: 'currency',
            deltaPct: 0,
        },
        {
            title: 'Total Revenue for FLE',
            num: 0,
            numType: 'currency',
            deltaPct: 0,
        },
        {
            title: 'Total Revenue for Other Orgs',
            num: 0,
            numType: 'currency',
            deltaPct: 0,
        },
    ];

    public secondRowStats = [
        {
            title: 'Total Subscribers',
            num: 0,
            numType: 'none',
            deltaPct: 0,
        },
        {
            title: 'Subscribers',
            num: 0,
            numType: 'none',
            deltaPct: 0,
        }
    ];

    public topThreeOrganizations: Array<ITopThreeOrganization> = [];
    public topThreeBusiness: Array<ITopThreeBusiness> = [];

    constructor(private dashboardService: DashboardService) {

    }

    ngOnInit() {
        this.dashboardService.getDashboardStatistics().pipe(map(statistics => {
            // console.log('Loading Statistics: ', statistics);
            this.dashboardStatistics = statistics;

            this.topRowStats[TOTAL_REVENUE_INDEX].num = this.dashboardStatistics.totalRevenue;
            this.topRowStats[TOTAL_REVENUE_FOR_FLE_INDEX].num = this.dashboardStatistics.totalRevenueForFLE;
            this.topRowStats[TOTAL_REVENUE_FOR_OTHER_ORGS_INDEX].num = this.dashboardStatistics.totalRevenueForOtherOrgs;
            this.secondRowStats[TOTAL_SUBSCRIBERS_INDEX].num = this.dashboardStatistics.totalSubscribers;
            this.secondRowStats[SUBSCRIBERS_GAINED_OR_LOST_INDEX].num = this.dashboardStatistics.totalSubscribersGainedOrLostInAMonth;
            
            if (this.dashboardStatistics.totalSubscribersGainedOrLostInAMonth >= 0) {
                this.secondRowStats[SUBSCRIBERS_GAINED_OR_LOST_INDEX].title += ' Gained';
            } else {
                this.secondRowStats[SUBSCRIBERS_GAINED_OR_LOST_INDEX].title += ' Lost';
            }

            this.topThreeOrganizations = this.dashboardStatistics.topThreeOrganizations;
            this.topThreeBusiness = this.dashboardStatistics.topThreeBusinesses;

        }), tap(_ => {
            this.isLoading = false;
        })).subscribe();
    }
}
