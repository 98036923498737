import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import {
    IApiDataResponse,
    IApiResponse,
} from '../../models/api-response.interface';
import { IDataMapping } from 'src/app/modules/core/models/mapping';

@Injectable({
    providedIn: 'root',
})
export class DataMappingApiService {
    private MAPPING_API = `${environment.apiBaseURL}DataMapping`;

    constructor(private http: HttpClient) {}

    public createMapping(
        mapping: IDataMapping,
    ): Observable<IApiDataResponse<IDataMapping>> {
        return this.http.post<IApiDataResponse<IDataMapping>>(
            `${this.MAPPING_API}`,
            mapping,
        );
    }

    public deleteMapping(id: string): Observable<IApiResponse> {
        return this.http.delete<IApiResponse>(`${this.MAPPING_API}?id=${id}`);
    }

    public updateMapping(
        mapping: IDataMapping,
    ): Observable<IApiDataResponse<IDataMapping>> {
        return this.http.put<IApiDataResponse<IDataMapping>>(
            `${this.MAPPING_API}`,
            mapping,
        );
    }

    public getMappings(): Observable<IApiDataResponse<IDataMapping[]>> {
        return this.http.get<IApiDataResponse<IDataMapping[]>>(
            `${this.MAPPING_API}/List`,
        );
    }

    public getMappingById(
        id: string,
    ): Observable<IApiDataResponse<IDataMapping>> {
        return this.http.get<IApiDataResponse<IDataMapping>>(
            `${this.MAPPING_API}/ById?id=${id}`,
        );
    }
}
