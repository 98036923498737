<div class="d-flex flex-row justify-content-center align-item-center">
  <div class="card shadow-sm" style="width: 500px">
    <div class="card-header">Mappings</div>
    <div class="card-body">
      <div class="justify-content-end">
        <button class="btn btn-primary" (click)="navigateToAddPage()">
          Create New
        </button>
      </div>
      <ul class="list-unstyled">
        <li class="" *ngFor="let mapping of mappings; let i = index">
          <div
            class="d-flex align-items-center py-3 px-2 flex-wrap justify-content-between"
          >
            <div>{{ mapping.name }}</div>
            <div class="d-flex justify-content-end">
              <button
                pButton
                pRipple
                label="Edit"
                icon="pi pi-pencil"
                class="p-button-text"
                (click)="navigateToEditPage(mapping.id)"
              ></button>
              <button
                pButton
                pRipple
                label="Delete"
                icon="pi pi-trash"
                class="p-button-text"
                (click)="confirmDelete($event, mapping.id)"
              ></button>
            </div>
          </div>
          <p-divider *ngIf="i < mappings.length - 1"></p-divider>
        </li>
      </ul>
    </div>
  </div>
</div>
<p-confirmPopup></p-confirmPopup>
