import { DisplayColumn } from 'src/app/modules/shared/models/display-column.model';

export class TeamMember {
    id: number;
    organizationId: number;
    userId: string;
    firstName: string;
    lastName: string;
    email:string;
    salesCode: string;
    createdDt: Date;
    createdBy: string;
    modifiedDt: Date;
    modifiedBy: string;
    suspendedDt: Date;
    deletedDt: Date;

    constructor(init?: Partial<TeamMember>) {
        this.id = init?.id;
        this.organizationId = init?.organizationId ?? null;
        this.userId = init?.userId ?? null;
        this.firstName = init?.firstName ?? '';
        this.lastName = init?.lastName ?? '';
        this.email = init?.email ?? '';
        this.salesCode = init?.salesCode ?? '';
        this.createdDt = init?.createdDt ?? null;
        this.createdBy = init?.createdBy ?? '';
        this.modifiedDt = init?.modifiedDt ?? null;
        this.modifiedBy = init?.modifiedBy ?? '';
        this.suspendedDt = init?.suspendedDt ?? null;
        this.deletedDt = init?.deletedDt ?? null;
    }

    static asDisplayColumns(isActionable: boolean = false): DisplayColumn[] {
        const cols: DisplayColumn[] = [
            { title: 'First Name', def: 'firstName', type: 'text' },
            { title: 'Last Name', def: 'lastName', type: 'text' },
            { title: 'Sales Code', def: 'salesCode', type: 'text' },
            { title: 'Email', def: 'email', type: 'text' },
            // { title: 'Status', def: 'status', type: 'status' },
        ];

        const actionCols: DisplayColumn[] = [
            { title: '', def: 'edit', text: 'Edit', type: 'button' },
            // { title: '', def: 'suspend', text: 'Suspend', type: 'button' },
            { title: '', def: 'delete', text: 'Delete', type: 'button' },
        ];

        return isActionable ? cols.concat(actionCols) : cols;
    }
}
