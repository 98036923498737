import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { passwordMatch } from '../../../core/validators/confirm.validator';

@Component({
    selector: 'app-password-confirmation',
    templateUrl: './password-confirmation.component.html',
    styleUrls: ['./password-confirmation.component.scss'],
})
export class PasswordConfirmationComponent implements OnInit {
    public form: FormGroup;

    // at least 1 of each: digit, lowercase character, uppercase, symbol
    // no whitespace
    // min length: 6
    private passwordPattern =
        '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!.*\\s).{6,}$';

  @Output() passwordSubmit: EventEmitter<string> = new EventEmitter<string>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
      this.form = this.fb.group(
          {
              password: [
                  '',
                  [Validators.required, Validators.pattern(this.passwordPattern)],
              ],
              confirmedPassword: ['', [Validators.required]],
          },
          {
              validator: passwordMatch,
          },
      );
  }

  public validate(): boolean {
      if (!this.form.valid) return false;

      const formData = this.form.value;
      return formData.password === formData.confirmedPassword;
  }

  public changeVisibility(): void {
      const x = (document.getElementById('passwordInput') as HTMLInputElement).type;
      if (x === 'password')
          (document.getElementById('passwordInput') as HTMLInputElement).type =
        'text';
      else if (x === 'text')
          (document.getElementById('passwordInput') as HTMLInputElement).type =
        'password';
      const y = (
      document.getElementById('confirmPasswordInput') as HTMLInputElement
      ).type;
      if (y === 'password')
          (
        document.getElementById('confirmPasswordInput') as HTMLInputElement
          ).type = 'text';
      else if (y === 'text')
          (
        document.getElementById('confirmPasswordInput') as HTMLInputElement
          ).type = 'password';
  }

  public onSubmit(): void {
      if (!this.form.valid) return;
      const formData = this.form.value;
      this.passwordSubmit.emit(formData.password);
  }
}
