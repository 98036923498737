<div class="d-flex flex-row justify-content-center align-item-center">
    <div class="card shadow-sm" style="width: 42rem">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-start align-items-center">
                <div class="title">
                    <ng-content select="[title]"></ng-content>
                </div>
                <form
                    [formGroup]="form"
                    style="width: 100%"
                >
                    <p-dropdown
                        formControlName="organizationId"
                        [options]="organizationList"
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Select Organization"
                    ></p-dropdown>
                    <div class="d-flex flex-row justify-content-between">
                        <div class="d-flex flex-row align-items-bottom flex-fill me-5">
                            <label for="firstName" class="col-form-label flex-grow-1 p-fluid"
                            >First Name
                                <input
                                    type="text"
                                    id="firstName"
                                    formControlName="firstName"
                                    placeholder="Enter First Name"
                                    [ngClass]="{
                                        'form-control': true,
                                        'is-invalid':
                                        form.controls['firstName'].invalid &&
                                        (form.controls['firstName'].dirty ||
                                        form.controls['firstName'].touched)
                                    }"
                                />
                                <ng-container
                                    *ngIf="
                                        form.controls['firstName'].invalid &&
                                        (form.controls['firstName'].dirty ||
                                        form.controls['firstName'].touched)
                                    "
                                >
                                    <div
                                        class="invalid-feedback"
                                        *ngIf="form.controls['firstName'].errors?.['required']"
                                    > Please enter a first name.
                                    </div>
                                </ng-container>
                            </label>
                        </div>
                        <div class="d-flex flex-row align-items-bottom flex-fill">
                            <label for="lastName" class="col-form-label flex-grow-1 p-fluid"
                            >Last Name
                                <input
                                    type="text"
                                    id="lastName"
                                    formControlName="lastName"
                                    placeholder="Enter Last Name"
                                    [ngClass]="{
                                        'form-control': true,
                                        'is-invalid':
                                        form.controls['lastName'].invalid &&
                                        (form.controls['lastName'].dirty ||
                                        form.controls['lastName'].touched)
                                    }"
                                />
                                <ng-container
                                    *ngIf="
                                        form.controls['lastName'].invalid &&
                                        (form.controls['lastName'].dirty ||
                                        form.controls['lastName'].touched)
                                    "
                                >
                                    <div
                                        class="invalid-feedback"
                                        *ngIf="form.controls['lastName'].errors?.['required']"
                                    > Please enter a last name.
                                    </div>
                                </ng-container>
                            </label>
                        </div>
                    </div>
                    <div class="d-flex flex-row align-items-bottom flex-fill">
                        <label for="email" class="col-form-label flex-grow-1 p-fluid"
                        >Email
                            <input
                                type="email"
                                id="email"
                                formControlName="email"
                                placeholder="Enter Email"
                                [ngClass]="{
                                    'form-control': true,
                                    'is-invalid':
                                    form.controls['email'].invalid &&
                                    (form.controls['email'].dirty ||
                                    form.controls['email'].touched)
                                }"
                            />
                            <ng-container
                                *ngIf="
                                    form.controls['email'].invalid &&
                                    (form.controls['email'].dirty ||
                                    form.controls['email'].touched)
                                "
                            >
                                <div
                                    class="invalid-feedback"
                                    *ngIf="form.controls['email'].errors?.['required']"
                                > Please enter an email.
                                </div>
                                <div
                                    class="invalid-feedback"
                                    *ngIf="form.controls['email'].errors?.['email']"
                                > Please enter a valid email.
                                </div>
                            </ng-container>
                        </label>
                    </div>
                    <br>
                    <div class="d-flex justify-content-center mt-2">
                        <button 
                            saveAndAdd
                            type="submit"
                            class="btn btn-secondary btn-lg"
                            [disabled]="submittingAndAdding"
                            (click)="saveAndAdd()"
                        >
                            <ng-container *ngIf="submittingAndAdding; else defaultSaveAndAdd">
                                <div class="spinner-border spinner-border-sm" role="status"></div>
                                Creating...
                            </ng-container>
                            <ng-template #defaultSaveAndAdd>Create and Add Another Team Member</ng-template>
                        </button>
                    </div>
                    <div class="d-flex justify-content-center mt-2">
                        <button
                            cancelButton
                            type="button"
                            class="btn btn-secondary btn-lg me-2"
                            (click)="onCancel()"
                        >
                            Cancel
                        </button>
                        <button
                            submitButton
                            type="submit"
                            class="btn btn-primary btn-lg ms-2"
                            [disabled]="submitting"
                            (click)="save()"
                        >
                            <ng-container *ngIf="submitting; else default">
                                <div class="spinner-border spinner-border-sm" role="status"></div>
                                Creating...
                            </ng-container>
                            <ng-template #default>Create</ng-template>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>