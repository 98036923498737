import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './guards/auth.guard';
import { SimplePageLayoutComponent } from './layouts/simple-page-layout/simple-page-layout.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { RouterModule } from '@angular/router';
import { NotFoundComponent } from './pages/error-pages/not-found/not-found.component';
import { ForbiddenComponent } from './pages/error-pages/forbidden/forbidden.component';
import { UnauthorizedComponent } from './pages/error-pages/unauthorized/unauthorized.component';
import { SharedModule } from '../shared/shared.module';
import { ErrorCardComponent } from './components/error-card/error-card.component';
import { AuthAbstractService } from './services/auth/auth-abstract.service';
import { AuthService } from './services/auth/auth.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { LoginGuard } from './guards/login.guard';
import { AdminGuard } from './guards/admin.guard';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserManagementService } from 'src/app/modules/admin/users/services/user-management/user-management.service';
import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';
import { DragDropModule } from 'primeng/dragdrop';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { FileSelectorComponent } from './components/file-selector/file-selector.component';
import { DividerModule } from 'primeng/divider';
import { UploadDocumentsComponent } from './components/upload-documents/upload-documents.component';
import { DocumentDetailsComponent } from './components/document-details/document-details.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TreeSelectModule } from 'primeng/treeselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NestedSelectorComponent } from './components/nested-selector/nested-selector.component';
import { TooltipModule } from 'primeng/tooltip';
import { ChipModule } from 'primeng/chip';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { DocumentStatusComponent } from './components/document-status-dropdown/document-status.component';
import { CategoryStatusComponent } from './components/category-status-dropdown/category-status.component';
import { DocumentCategoryComponent } from './components/document-category-dropdown/document-category.component';
import { UploadProgressComponent } from './components/upload-progress/upload-progress.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { AccountComponent } from './pages/account/account.component';
import { ActionTableComponent } from './components/action-table/action-table.component';
import { BusinessAdminComponent } from './pages/business-admin/business-admin.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { OrgAdminComponent } from './pages/org-admin/org-admin.component';
import { TeamMembersComponent } from './pages/team-members/team-members.component';
import { OrgAdminInfoComponent } from './pages/org-admin/org-admin-info/org-admin-info.component';
import { OverviewTableComponent } from './pages/dashboard/overview-table/overview-table.component';
import { StatCardComponent } from './pages/dashboard/stat-card/stat-card.component';
import { CdkTableModule } from '@angular/cdk/table';
import { BasicActionTableComponent } from './components/action-table/basic-action-table/basic-action-table.component';
import { PaginatorModule } from 'primeng/paginator';
import { InputMaskModule } from 'primeng/inputmask';
import { AddOrgAdminComponent } from './pages/org-admin/add-org-admin/add-org-admin.component';
import { EditOrgAdminComponent } from './pages/org-admin/edit-org-admin/edit-org-admin.component';
import { TeamMemberInfoComponent } from './pages/team-members/team-member-info/team-member-info.component';
import { AddTeamMemberComponent } from './pages/team-members/add-team-member/add-team-member.component';
import { EditTeamMemberComponent } from './pages/team-members/edit-team-member/edit-team-member.component';

@NgModule({
    declarations: [
        SimplePageLayoutComponent,
        AppLayoutComponent,
        NotFoundComponent,
        ForbiddenComponent,
        UnauthorizedComponent,
        ErrorCardComponent,
        FileSelectorComponent,
        UploadDocumentsComponent,
        DocumentDetailsComponent,
        NestedSelectorComponent,
        DocumentStatusComponent,
        DocumentCategoryComponent,
        CategoryStatusComponent,
        UploadProgressComponent,
        AccountComponent,
        ActionTableComponent,
        BusinessAdminComponent,
        DashboardComponent,
        OrgAdminComponent,
        TeamMembersComponent,
        OrgAdminInfoComponent,
        TeamMemberInfoComponent,
        OverviewTableComponent,
        StatCardComponent,
        BasicActionTableComponent,
        AddOrgAdminComponent,
        EditOrgAdminComponent,
        AddTeamMemberComponent,
        EditTeamMemberComponent
    ],
    imports: [
        CommonModule,
        CdkTableModule,
        RouterModule,
        HttpClientModule,
        SharedModule,
        ButtonModule,
        BreadcrumbModule,
        DialogModule,
        ConfirmDialogModule,
        DropdownModule,
        FormsModule,
        TableModule,
        DragDropModule,
        FileUploadModule,
        AutoCompleteModule,
        InputTextModule,
        InputTextareaModule,
        CheckboxModule,
        DividerModule,
        TreeSelectModule,
        ProgressSpinnerModule,
        InputTextareaModule,
        TooltipModule,
        ChipModule,
        ConfirmPopupModule,
        ProgressBarModule,
        ReactiveFormsModule,
        PaginatorModule,
        InputMaskModule,
    ],
    providers: [
        AdminGuard,
        AuthGuard,
        LoginGuard,
        UserManagementService,
        { provide: AuthAbstractService, useClass: AuthService },
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
})
export class CoreModule {}
