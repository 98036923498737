import { Component, Input } from '@angular/core';
import { Overview } from './models/overview.model';
import { DisplayColumn } from 'src/app/modules/shared/models/display-column.model';
import { OverviewDataSource } from './models/overview.data-source';

@Component({
    selector: 'app-overview-table',
    templateUrl: './overview-table.component.html',
    styleUrls: ['./overview-table.component.scss'],
})
export class OverviewTableComponent {
  @Input() tableTitle: string = 'Top 3';

  displayedColumns: DisplayColumn[] = Overview.asDisplayColumns();
  colDefs: string[] = this.displayedColumns.map((c) => c.def);
  dataSource = new OverviewDataSource();

  constructor() {}
}
