<div class="d-flex flex-row justify-content-center align-item-center">
  <div class="card shadow-sm">
    <div class="card-header">Data Mapping</div>
    <div class="card-body">
      <div
        *ngIf="!mapping; else editor"
        class="d-flex flex-column justify-content-start"
      >
        <div>
          <button
            class="btn btn-primary me-0 mt-md-0"
            (click)="openFilePicker()"
          >
            Use File Columns
          </button>
          OR
          <button
            class="btn btn-primary me-0 ms-2 mt-md-0"
            (click)="addColumnsManually()"
          >
            Add Columns Manually
          </button>
        </div>
      </div>
      <ng-template #editor>
        <app-mapping-editor
          [mapping]="mapping"
          [mappedToColumns]="mappingService.mappedToColumns"
          [fileColumns]="fileColumns"
          (onMappingChange)="updateMapping($event)"
          (onSave)="save()"
          (onPreview)="preview()"
          (onNewFilePreview)="newFilePreview()"
          (onCancel)="cancel()"
        ></app-mapping-editor>
      </ng-template>
    </div>
  </div>
</div>
