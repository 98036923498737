import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { DataMappingApiService } from '../../core/services/data-mapping-api/data-mapping-api.service';
import { DataMapping, IDataMapping } from '../../core/models/mapping';
import { MappingPreviewComponent } from '../components/mapping-preview/mapping-preview.component';
import { DialogService } from 'primeng/dynamicdialog';
import { ToastService } from '../../core/services/toast/toast.service';

type AOA = any[][];

@Injectable()
export class DataMappingService {
    public mappedToColumns: string[] = [
        'Work Order',
        'Order Date',
        'Item Number',
        'Quantity',
        'Price',
    ];
    public mappedData: any;
    public mappedHeaders: string[];
    private _currentFileData: any = null;

    get currentFileData() {
        return this._currentFileData;
    }

    constructor(
    private api: DataMappingApiService,
    private dialogService: DialogService,
    private toastService: ToastService,
    ) {}

    public createMapping(mapping: DataMapping): Observable<boolean> {
        const payload: IDataMapping = {
            name: mapping.name,
            headerRowIndex: mapping.headerRowIndex,
            columns: JSON.stringify(mapping.columns),
        };
        return this.api.createMapping(payload).pipe(
            map((res) => {
                this.toastService.success(res.message);
                return true;
            }),
        );
    }

    public updateMapping(mapping: DataMapping): Observable<boolean> {
        const payload: IDataMapping = {
            id: mapping.id,
            name: mapping.name,
            headerRowIndex: mapping.headerRowIndex,
            columns: JSON.stringify(mapping.columns),
        };
        return this.api.updateMapping(payload).pipe(
            map((res) => {
                this.toastService.success(res.message);
                return true;
            }),
        );
    }

    public readFileData(file: File, selectedSheetName: string) {
        const reader: FileReader = new FileReader();
        const doneReading = new BehaviorSubject<boolean>(null);
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            // const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

            // const ws: XLSX.WorkSheet = wb.Sheets[selectedSheetName];

            /* save data */
            const data: any = null;
            // const data = <AOA>(
            // XLSX.utils.sheet_to_json(ws, { header: 1, range: 0, defval: null })
            // );
            this._currentFileData = data;
            doneReading.next(true);
        };
        // reader.readAsBinaryString(file);
        return doneReading;
    }

    public mapData(mapping: DataMapping): boolean {
        const mappedData: any[] = [];
        const columnExceptions: string[] = [];
        let headers: string[] = [];

        if (mapping.headerRowIndex) {
            headers = this._currentFileData[mapping.headerRowIndex - 1];
        } else {
            this._currentFileData[0].forEach((_: any, index: number) => {
                headers.push(`Column ${index + 1}`);
            });
        }

        const startRow = mapping.headerRowIndex ?? 0;

        if (startRow >= this._currentFileData.length) {
            this.toastService.error('Not Enough File Data');
            return false;
        }

        for (let i = startRow; i < this._currentFileData.length; i++) {
            const row: any = {};
            mapping.columns.forEach((column) => {
                if (headers.indexOf(column.name) === -1 && column.required) {
                    columnExceptions.push(column.name);
                } else {
                    row[column.mappedTo] =
            this._currentFileData[i][headers.indexOf(column.name)];
                }
            });
            mappedData.push(row);
        }
        if (columnExceptions.length) {
            this.toastService.error(
                `Columns not found:${  columnExceptions.toString()}`,
            );
            return false;
        }
        this.mappedData = mappedData;
        this.mappedHeaders = mapping.columns.map((column) => column.mappedTo);
        return true;
    }

    public previewData() {
        this.dialogService.open(MappingPreviewComponent, {
            header: 'Preview',
            width: '700px',
            height: '600px',
            data: {
                data: this.mappedData,
                headers: this.mappedHeaders,
            },
        });
    }
}
