// Globally used constant values

/* HTTP STATUS CODES */
// Information --------------------------
// NOTE: Info codes are currently unused by project, add if needed 100, 101, 102, 103
export const HTTP_SUCCESS = {
    SUCCESS: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204
    // 203, 205, 206, 207, 208, 226 omitted
};

export const HTTP_REDIRECTS = {
    MOVED_PERMANENTLY: 301,
    FOUND: 302,
    NOT_MODIFIED: 304,
    TEMPORARY_REDIRECT: 307,
    PERMANENT_REDIRECT: 308
    // 300, 303, 305 omitted; 306 reserved
};

export const HTTP_CLIENT_ERRORS = {
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    PAYMENT_REQUIRED: 402,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    NOT_ACCEPTABLE: 406,
    REQUEST_TIMEOUT: 408,
    TOO_MANY_REQUESTS: 429,
    // I believe it's required to include this
    IM_A_TEAPOT: 418
    // many codes omitted, add as needed
};

export const HTTP_SERVER_ERRORS = {
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504,
    LOOP_DETECTED: 508,
    NETWORK_AUTHENTICATION_REQUIRED: 511
    // 505, 506, 507, 510 omitted
};

export const HTTP_NON_STANDARD = {
    MFA_REQUIRED: 250
};
