<h1>Dashboard</h1>
<h3>Welcome {{ username }}</h3>
<div class="content d-flex justify-content-center w-100" *ngIf="isLoading">
  <p-progressSpinner></p-progressSpinner>
</div>
<div *ngIf="!isLoading" class="content">
  <div class="stat-cards">
    <div *ngFor="let stat of topRowStats">
      <app-stat-card [title]="stat.title" [num]="stat.num" [numType]="stat.numType"
        [deltaPct]="stat.deltaPct"></app-stat-card>
    </div>
  </div>
  <div class="stat-cards mt-1">
    <div *ngFor="let stat of secondRowStats">
      <app-stat-card [title]="stat.title" [num]="stat.num" [numType]="stat.numType"
        [deltaPct]="stat.deltaPct"></app-stat-card>
    </div>
  </div>
  <div class="table-title">Top 3 Organizations</div>
  <table class="table">
    <th>Name</th>
    <th>Total Subscribers</th>
    <th>Revenue</th>
    <tr *ngFor="let org of topThreeOrganizations">
      <td>{{org.name}}</td>
      <td>{{org.totalSubscribers}}</td>
      <td>{{org.totalSubscribers * 15 | currency}}</td>
    </tr>
  </table>

  <div class="table-title">Top 3 Businesses</div>
  <table class="table">
    <th>Name</th>
    <th>Total Coupon Redemptions</th>
    <tr *ngFor="let org of topThreeBusiness">
      <td>{{org.name}}</td>
      <td>{{org.totalRedeemedCoupons}}</td>
    </tr>
  </table>
</div>