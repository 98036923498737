import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';

@Component({
    selector: 'app-forbidden',
    templateUrl: './forbidden.component.html',
    styleUrls: ['./forbidden.component.scss'],
})
export class ForbiddenComponent implements OnInit {
    constructor(private location: Location) {}

    ngOnInit(): void {}

    public onCancel(): void {
        this.location.back();
    }
}
