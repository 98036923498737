import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IRole, Role } from '../../models/role';
import { environment } from '../../../../../environments/environment';
import {
    IApiDataResponse,
    IApiResponse,
} from '../../models/api-response.interface';

@Injectable({
    providedIn: 'root',
})
export class RoleApiService {
    private ROLE_API = `${environment.apiBaseURL}Role`;

    constructor(private http: HttpClient) {}

    public createRole(roleRequestBody: any): Observable<IApiDataResponse<IRole>> {
        return this.http.post<IApiDataResponse<IRole>>(
            `${this.ROLE_API}`,
            roleRequestBody,
        );
    }

    public deleteRole(id: string): Observable<IApiResponse> {
        return this.http.delete<IApiResponse>(`${this.ROLE_API}?id=${id}`);
    }

    public updateRole(role: Role): Observable<IApiDataResponse<IRole>> {
        const roleRequestBody = { name: role.name };
        return this.http.put<IApiDataResponse<IRole>>(
            `${this.ROLE_API}?id=${role.id}`,
            roleRequestBody,
        );
    }

    public getRoles(): Observable<IApiDataResponse<IRole[]>> {
        return this.http.get<IApiDataResponse<IRole[]>>(`${this.ROLE_API}/List`);
    }

    public getRoleById(id: string): Observable<IApiDataResponse<IRole>> {
        return this.http.get<IApiDataResponse<IRole>>(
            `${this.ROLE_API}/ById?id=${id}`,
        );
    }
}
