import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, tap } from 'rxjs';
import { AuthAbstractService } from '../../../core/services/auth/auth-abstract.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
    public token: string;
    public userName: string;
    public submitting: boolean = false;
    public isMobileUser: string;
    public displaySucessMessage: boolean = false;

    constructor(
    private authSvc: AuthAbstractService,
    private route: ActivatedRoute,
    private router: Router,
    ) {}

    ngOnInit(): void {
        this.token = this.route.snapshot.queryParams['token'];
        this.userName = this.route.snapshot.queryParams['username'];
        this.isMobileUser = this.route.snapshot.queryParams['isMobileUser'].toLowerCase();
    }

    public onPasswordSubmit(password: string): void {
        const resetPasswordRqt = {
            token: this.token,
            username: this.userName,
            password: password,
        };

        this.submitting = true;
        this.authSvc
            .resetPassword(resetPasswordRqt)
            .pipe(
                tap(() => this.isMobileUser === "true" ? this.displaySucessMessage = true : this.router.navigateByUrl('/login')),
                finalize(() => { this.submitting = false; }),
            )
            .subscribe();
    }
}
