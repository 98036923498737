import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DisplayColumn } from 'src/app/modules/shared/models/display-column.model';
import { Action } from './models/action';
import { TDataSource } from 'src/app/modules/shared/models/data-source';
import { Organization } from '../../pages/org-admin/models/organization.model';
import { Business } from '../../pages/business-admin/models/business.model';

const DEFAULT_ROW_COUNT = 10;

@Component({
    selector: 'app-action-table',
    templateUrl: './action-table.component.html',
    styleUrls: ['./action-table.component.scss'],
})
export class ActionTableComponent implements OnInit {
    _dataSource: TDataSource<any>;
    get dataSource(): TDataSource<any> {
        return this._dataSource;
    }
    @Input() set dataSource(value: TDataSource<any>) {
        this._dataSource = value;
        this.setDataOnPage();
    }

    @Input() dataName: string = 'Item';
    @Input() columns: DisplayColumn[];
    @Input() actions: Action[] = [];
    @Input() organizations: Organization[];
    @Input() filterComparator?: (item: any, value: string) => boolean;
    colDefs: string[];
    @Output() actionEvent: EventEmitter<{ action: string; id: string; orgId: string }> =
        new EventEmitter<{ action: string; id: string; orgId: string }>();
    searchField: string = '';
    first: number = 0;
    rows: number = DEFAULT_ROW_COUNT;
    dataOnPage: TDataSource<any>;
    selectedOrganization: string;

    constructor() {}

    ngOnInit(): void {
        this.setDataOnPage();
    }

    setDataOnPage(){
        if (this.dataSource != null) {
            this.colDefs = this.columns?.map((c) => c.def);
            this.dataSource.data.subscribe((items: any) => {
                const page = items.slice(this.first, this.first + this.rows);
                this.dataOnPage = page;
            });
        }
    }

    raiseEvent(action: string, id?: string) {
        this.actionEvent.emit({ action: action, id: id, orgId: this.selectedOrganization });
    }

    onPageChange(event: any) {
        this.dataSource.data.subscribe((items: any) => {
            const page = items.slice(event.first, event.first + this.rows);
            this.dataOnPage = page;
        });
    }

    calcStatus(element: any): string {
        if (this.dataName === 'Business') {
            return Business.getStatus(element);
        }
        if (this.dataName === 'Organization') {
            return Organization.getStatus(element);
        }
        return 'N/A';
    }

    onOrganizationSelection(event: any, action: string) {
        this.raiseEvent(action, event.value);
    }
}
