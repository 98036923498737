import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthFlowModule } from './modules/auth-flow/auth-flow.module';
import { CoreModule } from './modules/core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { DialogService } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { DataMapperModule } from './modules/data-mapper/data-mapper.module';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        CoreModule,
        AuthFlowModule,
        DataMapperModule,
        BrowserAnimationsModule, // required animations module
        ToastModule,
        DialogModule,
        ButtonModule,
        FormsModule,
        DropdownModule,
        MsalModule.forRoot(
            new PublicClientApplication({
                auth: {
                    clientId: environment.azureADAuthProvider.clientId, // Application (client) ID from the app registration
                    authority: `https://login.microsoftonline.com/${environment.azureADAuthProvider.tenantId}`, // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
                    redirectUri: '/', // This is your redirect URI
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
                },
            }),
            null,
            null,
        ),
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent, MsalRedirectComponent],
    providers: [MessageService, DialogService, ConfirmationService],
})
export class AppModule {}
