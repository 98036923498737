import { HttpClient, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreatePasswordRequest } from '../../models/create-password-request';
import { LoginRequest } from '../../models/login-request.interface';
import { environment } from '../../../../../environments/environment';
import {
    IApiDataResponse,
    IApiResponse,
} from '../../models/api-response.interface';
import { IUser } from '../../models/user';

@Injectable({
    providedIn: 'root',
})
export class AuthApiService {
    private AUTH_API = `${environment.apiBaseURL}Auth`;

    constructor(private http: HttpClient) {}

    // We need to observe the actual response object so we can
    // known which code path to take in the event that the user's
    // password is expired, the user requires 2fa or simply the credentials
    // supplied were incorrect / invalid or server error has occurred.
    public login(loginRequestBody: LoginRequest): Observable<HttpResponseBase> {
        return this.http.post<HttpResponseBase>(
            `${this.AUTH_API}/login`,
            loginRequestBody,
            { observe: 'response' },
        );
    }

    public externalLogin(
        token: string,
        provider: string,
    ): Observable<HttpResponseBase> {
        return this.http.post<HttpResponseBase>(
            `${this.AUTH_API}/externalLogin/${provider}`,
            { token: token },
            { observe: 'response' },
        );
    }

    public getExternalLoginProviders(): Observable<IApiDataResponse<string[]>> {
        return this.http.get<IApiDataResponse<string[]>>(
            `${this.AUTH_API}/externalLoginProviders`,
        );
    }

    public register(
        createPasswordRequestBody: CreatePasswordRequest,
    ): Observable<IApiDataResponse<IUser>> {
        const url = `${this.AUTH_API}/Register`;
        return this.http.post<IApiDataResponse<IUser>>(
            url,
            createPasswordRequestBody,
        );
    }

    public refreshToken(): Observable<IApiDataResponse<string>> {
        return this.http.get<IApiDataResponse<string>>(`${this.AUTH_API}/Refresh`);
    }

    public impersonate(id: string): Observable<IApiDataResponse<string>> {
        return this.http.get<IApiDataResponse<string>>(
            `${this.AUTH_API}/Impersonate?id=${id}`,
        );
    }

    public sendInitial2FACode(
        username: string,
        method: string,
    ): Observable<IApiResponse> {
        return this.http.get<IApiResponse>(
            `${this.AUTH_API}/SendInitial2FACode?username=${username}&method=${method}`,
        );
    }

    public checkInitial2FACode(
        username: string,
        code: string,
    ): Observable<IApiResponse> {
        return this.http.post<IApiResponse>(
            `${this.AUTH_API}/CheckInitial2FACode?username=${username}&code=${code}`,
            {},
        );
    }
}
