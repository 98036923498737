import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { map, Observable, of, switchMap } from 'rxjs';
import { UserRole } from '../../shared/statuses';
import { UserService } from '../services/user/user.service';
import { AuthGuard } from './auth.guard';

@Injectable()
export class AdminGuard implements CanLoad {
    constructor(
    private router: Router,
    private userSvc: UserService,
    private authGuard: AuthGuard,
    ) {}

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
        return this.checkRoleForRoute(route, segments);
    }

    private checkRoleForRoute(
        route: Route,
        segments: UrlSegment[],
    ): Observable<boolean | UrlTree> {
        return this.authGuard.canLoad(route, segments).pipe(
            switchMap((successOrUrlTree: boolean | UrlTree) => {
                if (successOrUrlTree instanceof UrlTree)
                    return of(successOrUrlTree as UrlTree);
                return this.userSvc.user$.pipe(
                    map((user) => {
                        if (user && user.roles.includes(UserRole.Admin)) return true;
                        return this.router.createUrlTree(['/forbidden']);
                    }),
                );
            }),
        );
    }
}
