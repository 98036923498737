import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IApiDataResponse } from '../models/api-response.interface';
import { IDashboardStatistics } from '../models/dashboard-statistics.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    constructor(private http: HttpClient) {}

    private DASHBOARD_API = `${environment.apiBaseURL}Dashboard`;

    public getDashboardStatistics(): Observable<IDashboardStatistics> {
        return this.http.get<IApiDataResponse<IDashboardStatistics>>(`${this.DASHBOARD_API}/GetDashboardStatistics`).pipe(map(response => response.data));
    }
}