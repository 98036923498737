import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Organization } from '../models/organization.model';
import { environment } from 'src/environments/environment';
import { IApiDataResponse } from '../../../models/api-response.interface';

@Injectable({
  providedIn: 'root',
})
export class OrgAdminApiService {
  private ORGANIZATION_API = `${environment.apiBaseURL}Organization`;

  constructor(private http: HttpClient) {}

  public getOrganizations(): Observable<IApiDataResponse<Organization[]>> {
    return this.http.get<IApiDataResponse<Organization[]>>(`${this.ORGANIZATION_API}`);
  }

  public createOrganization(organization: Organization): Observable<IApiDataResponse<Organization>> {
    return this.http.post<IApiDataResponse<Organization>>(`${this.ORGANIZATION_API}`, organization);
  }

  public getOrgAdminById(id: string): Observable<IApiDataResponse<Organization>> {
    return this.http.get<IApiDataResponse<Organization>>(`${this.ORGANIZATION_API}/ById?id=${id}`);
  }

  public updateOrganization(organization: Organization): Observable<IApiDataResponse<Organization>> {
    return this.http.post<IApiDataResponse<Organization>>(`${this.ORGANIZATION_API}/Update`, organization);
  }
}
