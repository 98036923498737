export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  userName: string;
  phoneNumber: string;
  emailConfirmed: boolean;
  emailSent: boolean;
  roles: string[];
  lastPasswordReset: Date;
  twoFactorEnabled: boolean;
  twoFactorType: string;
  twoFactorConfirmed: boolean;
  notificationsEnabled: boolean;
  profilePic: string;
}
export class User implements IUser {
    id: string;
    firstName: string;
    lastName: string;
    userName: string;
    phoneNumber: string;
    emailConfirmed: boolean;
    emailSent: boolean;
    roles: string[];
    lastPasswordReset: Date;
    twoFactorEnabled: boolean;
    twoFactorType: string;
    twoFactorConfirmed: boolean;
    notificationsEnabled: boolean;
    profilePic: string;

    constructor(init?: Partial<IUser>) {
        if (init?.id) this.id = init.id;
        this.firstName = init?.firstName ?? '';
        this.lastName = init?.lastName ?? '';
        this.userName = init?.userName ?? '';
        this.phoneNumber = init?.phoneNumber ?? '';
        this.emailConfirmed = init?.emailConfirmed ?? false;
        this.emailSent = init?.emailSent ?? false;
        this.roles = init?.roles ?? [];
        this.lastPasswordReset = init?.lastPasswordReset ?? null;
        this.twoFactorEnabled = init?.twoFactorEnabled ?? false;
        this.twoFactorType = init?.twoFactorType ?? 'email';
        this.twoFactorConfirmed = init?.twoFactorConfirmed ?? false;
        this.notificationsEnabled = init?.notificationsEnabled ?? false;
        this.profilePic = init?.profilePic ?? null;
    }
}
