import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { DataMapping } from 'src/app/modules/core/models/mapping';

export interface DropdownOption {
  name: string;
  disabled: boolean;
}

@Component({
    selector: 'app-mapping-editor',
    templateUrl: './mapping-editor.component.html',
    styleUrls: ['./mapping-editor.component.scss'],
})
export class MappingEditorComponent implements OnInit {
  @Input() mapping: DataMapping;
  @Input() mappedToColumns: string[];
  @Input() fileColumns: string[];
  @Output() onMappingChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() onPreview: EventEmitter<void> = new EventEmitter<void>();
  @Output() onNewFilePreview: EventEmitter<void> = new EventEmitter<void>();

  form: FormGroup;
  editableFileColumns: boolean = false;
  fileColumnOptions: DropdownOption[] = [];
  mappedToColumnOptions: DropdownOption[] = [];
  previewOptions: MenuItem[] = [
      {
          label: 'Choose File',
          icon: 'pi pi-file',
          command: () => {
              this.onNewFilePreview.emit();
          },
      },
  ];

  get columns() {
      return this.form.get('columns') as FormArray;
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
      this.fileColumnOptions = this.fileColumns.map((column) => {
          return { name: column, disabled: false };
      });
      this.mappedToColumnOptions = this.mappedToColumns.map((column) => {
          return { name: column, disabled: false };
      });

      this.form = this.fb.group({
          id: [null],
          name: [null, Validators.required],
          hasHeaderRow: [false],
          headerRowIndex: [null],
          columns: this.fb.array([]),
      });

      this.form.controls['hasHeaderRow'].valueChanges.subscribe(
          (hasHeaderRow) => {
              if (!hasHeaderRow) {
                  this.form.controls['headerRowIndex'].disable();
              } else {
                  this.editableFileColumns = true;
                  this.form.controls['headerRowIndex'].enable();
              }
          },
      );

      this.form.valueChanges.subscribe((_) => {
          this.onMappingChange.emit(this.form.getRawValue());
      });

      if (this.mapping) {
          this.form.patchValue(this.mapping);

          if (this.mapping.headerRowIndex) {
              this.form.controls['hasHeaderRow'].setValue(true);
          } else {
              this.form.controls['headerRowIndex'].disable();
          }

          this.mapping.columns.forEach((column) => {
              this.columns.push(
                  new FormGroup({
                      name: new FormControl(column.name, Validators.required),
                      mappedTo: new FormControl(column.mappedTo, Validators.required),
                      required: new FormControl(column.required),
                  }),
              );
              const fileColumnOption = this.fileColumnOptions.find(
                  (c) => c.name === column.name,
              );
              const mappedToColumnOption = this.mappedToColumnOptions.find(
                  (c) => c.name === column.mappedTo,
              );
              fileColumnOption.disabled = true;
              mappedToColumnOption.disabled = true;
          });
      } else {
          this.form.patchValue(new DataMapping());
      }
  }

  fileColumnSelect(columnName: string) {
      const fileColumnOption = this.fileColumnOptions.find(
          (c) => c.name === columnName,
      );
      fileColumnOption.disabled = true;
  }

  mappedToColumnSelect(columnName: string) {
      const mappedToColumnOption = this.mappedToColumnOptions.find(
          (c) => c.name === columnName,
      );
      mappedToColumnOption.disabled = true;
  }

  removeMapping(index: number) {
      const column = this.columns.controls[index].value;
      const fileColumnOption = this.fileColumnOptions.find(
          (c) => c.name === column.name,
      );
      const mappedToColumnOption = this.mappedToColumnOptions.find(
          (c) => c.name === column.mappedTo,
      );
      if (fileColumnOption) fileColumnOption.disabled = false;
      if (mappedToColumnOption) mappedToColumnOption.disabled = false;
      this.columns.removeAt(index);
  }

  addColumn() {
      this.columns.push(
          new FormGroup({
              name: new FormControl(null, Validators.required),
              mappedTo: new FormControl(null, Validators.required),
              required: new FormControl(false),
          }),
      );

      if (
          !this.form.controls['hasHeaderRow'].value &&
      this.columns.controls.length > this.fileColumnOptions.length
      ) {
          this.fileColumnOptions.push({
              name: `Column ${this.columns.controls.length}`,
              disabled: false,
          });
      }
  }

  onSaveEmit() {
      this.onSave.emit();
  }

  onPreviewEmit() {
      this.onPreview.emit();
  }

  onCancelEmit() {
      this.onCancel.emit();
  }
}
