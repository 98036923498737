import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IApiResponse } from 'src/app/modules/core/models/api-response.interface';
import { ToastService } from 'src/app/modules/core/services/toast/toast.service';
import { OrgAdminApiService } from './org-admin-api.service';
import { Organization } from '../models/organization.model';

@Injectable()
export class AddOrgAdminService {
    constructor(
        private organizationApi: OrgAdminApiService,
        private toast: ToastService,
    ) {}

    public create(organization: Organization): Observable<boolean> {
        return this.organizationApi.createOrganization(organization).pipe(
        map((response: IApiResponse) => {
            this.toast.success(response.message);
            return true;
        }),
        );
    }
}
