import { Validators } from '@angular/forms';
import { DisplayColumn } from 'src/app/modules/shared/models/display-column.model';

export class Business {
    id: number;
    name: string;
    description: string;
    logoImagePath: string;
    productImagePath?: string;
    createdDt: Date;
    createdBy: string;
    modifiedDt: Date;
    modifiedBy: string;
    suspendedDt?: Date;
    deletedDt?: Date;
    contactFirstName?: string;
    contactLastName?: string;
    contactEmailAddress?: string;
    contactAddress?: string;
    contactAddress2?: string;
    contactCity?: string;
    contactState?: string;
    contactZip?: string;
    contactPhoneNumber?: string;
    contractStartDt: Date;
    contractEndDt: Date;

    constructor(init?: Partial<Business>) {
        this.id = init?.id;
        this.name = init?.name ?? '';
        this.description = init?.description ?? '';
        this.logoImagePath = init?.logoImagePath ?? '';
        this.productImagePath = init?.productImagePath ?? '';
        this.createdDt = init?.createdDt;
        this.createdBy = init?.createdBy ?? '';
        this.modifiedDt = init?.modifiedDt;
        this.modifiedBy = init?.modifiedBy ?? '';
        this.suspendedDt = init?.suspendedDt;
        this.deletedDt = init?.deletedDt;
        this.contactFirstName = init?.contactFirstName ?? '';
        this.contactLastName = init?.contactLastName ?? '';
        this.contactEmailAddress = init?.contactEmailAddress ?? '';
        this.contactAddress = init?.contactAddress ?? '';
        this.contactAddress2 = init?.contactAddress2 ?? '';
        this.contactCity = init?.contactCity ?? '';
        this.contactState = init?.contactState ?? '';
        this.contactZip = init?.contactZip ?? '';
        this.contactPhoneNumber = init?.contactPhoneNumber ?? '';
        this.contractStartDt = init?.contractStartDt;
        this.contractEndDt = init?.contractEndDt;
    }

    static asDisplayColumns(isActionable: boolean = false): DisplayColumn[] {
        const cols: DisplayColumn[] = [
            { title: 'Name', def: 'name', type: 'text' },
            { title: 'Description', def: 'description', type: 'text' },
            { title: 'Email', def: 'contactEmailAddress', type: 'text' },
            { title: 'Contract', def: 'contract', type: 'date-range' },
            { title: 'Status', def: 'status', type: 'status' },
        ];

        const actionCols: DisplayColumn[] = [
            { title: '', def: 'suspend', text: 'Suspend', type: 'button' },
            { title: '', def: 'delete', text: 'Delete', type: 'button' },
        ];

        return isActionable ? cols.concat(actionCols) : cols;
    }

    static asForm() {
        return {
            name: ['', [Validators.required]],
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
        };
    }

    static getStatus(business: Business) {
        if (business.suspendedDt) {
            return 'Suspended';
        }

        const today = new Date();
        const start = new Date(business.contractStartDt);
        const end = new Date(business.contractEndDt);

        if (today > end) {
            return 'Past Due';
        }
        if (today <= start) {
            return 'Pending';
        }
        return 'Active';
    }
}
