import {
    ChangeDetectorRef,
    Component,
    NgZone,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginRequest } from '../../../core/models/login-request.interface';
import { AuthAbstractService } from '../../../core/services/auth/auth-abstract.service';
import {
    ILoginResponse,
    LoginResponseStatus,
} from 'src/app/modules/core/services/auth/auth.service';
import { UserApiService } from 'src/app/modules/core/services/user-api/user-api.service';
import { IApiDataResponse } from 'src/app/modules/core/models/api-response.interface';
import { IUser } from 'src/app/modules/core/models/user';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { Subject, tap } from 'rxjs';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    public form: FormGroup;
    public submitting: boolean = false;
    public twoFactor: boolean = false;
    public twoFactorType: string;

    public onDestroy$: Subject<boolean> = new Subject();

    constructor(
    private authSvc: AuthAbstractService,
    private fb: FormBuilder,
    private router: Router,
    private userApi: UserApiService,
    private userSvc: UserService,
    private ngZone: NgZone,
    private changeDetector: ChangeDetectorRef,
    ) {}

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            username: ['', [Validators.required]],
            password: ['', [Validators.required]],
            code: null,
        });
    }

    public onSubmit(): void {
        if (!this.form.valid) return;
        const loginRequestObject = this.form.value as LoginRequest;

        this.submitting = true;
        this.authSvc
            .login(loginRequestObject)
            .subscribe((response: ILoginResponse) => {
                switch (response.status) {
                    case LoginResponseStatus.Success: {
                        this.handleLoginSuccess();
                        break;
                    }
                    case LoginResponseStatus.Requires2FA: {
                        this.twoFactorType = response.info.data;
                        this.twoFactor = true;
                        this.router.navigateByUrl('/login');
                        break;
                    }
                    case LoginResponseStatus.PasswordExpired: {
                        const expiredPasswordResetUrl = response.info.data as string;
                        this.router.navigateByUrl(`/${expiredPasswordResetUrl}`);
                        break;
                    }
                    default:
                        break;
                }
                this.submitting = false;
            });
    }

    public handleLoginSuccess() {
        this.userApi
            .getUserDetails()
            .pipe(
                tap((response: IApiDataResponse<IUser>) => {
                    this.userSvc.setUser(response.data);
                    this.twoFactor = false;
                    this.router.navigateByUrl('/app');
                }),
            )
            .subscribe();
    }

    public changeVisibility(): void {
        const x = (document.getElementById('passwordInput') as HTMLInputElement)
            .type;
        if (x === 'password')
            (document.getElementById('passwordInput') as HTMLInputElement).type =
        'text';
        else if (x === 'text')
            (document.getElementById('passwordInput') as HTMLInputElement).type =
        'password';
    }
}
