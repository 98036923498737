import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { map, Observable } from 'rxjs';
import { AuthAbstractService } from '../services/auth/auth-abstract.service';

@Injectable()
export class LoginGuard implements CanActivate {
    constructor(
    private authSvc: AuthAbstractService,
    private router: Router,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
        return this.authSvc.checkAuthForRoute().pipe(
            map((isAllowed: boolean) => {
                if (!isAllowed) return true;
                return this.router.createUrlTree(['/app']);
            }),
        );
    }
}
