<div class="table-title">{{ tableTitle }}</div>
<table class="table" cdk-table [dataSource]="dataSource">
  <ng-container *ngFor="let col of displayedColumns">
    <!-- text (default) -->
    <ng-container *ngIf="col.type === 'text'">
      <ng-container [cdkColumnDef]="col.def">
        <th cdk-header-cell *cdkHeaderCellDef>{{ col.title }}</th>
        <td cdk-cell *cdkCellDef="let element">{{ element[col.def] }}</td>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="col.type === 'number'">
      <ng-container [cdkColumnDef]="col.def">
        <th cdk-header-cell *cdkHeaderCellDef>{{ col.title }}</th>
        <td cdk-cell *cdkCellDef="let element" style="text-align: right;">{{ element[col.def] }}</td>
      </ng-container>
    </ng-container>

    <!-- image -->
    <ng-container *ngIf="col.type === 'image'">
      <!-- TODO change <td> to display an image -->
      <ng-container [cdkColumnDef]="col.def">
        <th cdk-header-cell *cdkHeaderCellDef>{{ col.title }}</th>
        <td cdk-cell *cdkCellDef="let element">{{ element[col.def] }}</td>
      </ng-container>
    </ng-container>
  </ng-container>

  <tr cdk-header-row *cdkHeaderRowDef="colDefs"></tr>
  <tr cdk-row *cdkRowDef="let row; columns: colDefs"></tr>
</table>
