import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataMappingApiService } from 'src/app/modules/core/services/data-mapping-api/data-mapping-api.service';
import { DataMapping } from 'src/app/modules/core/models/mapping';
import { DialogService } from 'primeng/dynamicdialog';
import { FilePickerComponent } from 'src/app/modules/data-mapper/components/file-picker/file-picker.component';
import { DataMappingService } from '../../services/data-mapping.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-edit-mapping',
    templateUrl: './edit-mapping.component.html',
    providers: [DataMappingService],
})
export class EditMappingComponent implements OnInit {
    mapping: DataMapping;
    fileColumns: string[] = [];

    constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private mappingApi: DataMappingApiService,
    private dialogService: DialogService,
    public mappingService: DataMappingService,
    public location: Location,
    ) {}

    ngOnInit(): void {
        this.activatedRoute.params.subscribe((params) => {
            if (params['id'] !== 'undefined') {
                this.mappingApi.getMappingById(params['id']).subscribe((mapping) => {
                    this.mapping = new DataMapping(mapping.data);
                    this.mapping.columns.forEach((column) => {
                        this.fileColumns.push(column.name);
                    });
                });
            }
        });
    }

    updateMapping(mapping: DataMapping) {
        this.mapping = mapping;
    }

    save() {
        this.mappingService.updateMapping(this.mapping).subscribe((_) => {
            this.router.navigateByUrl('/app/data-mapper/list');
        });
    }

    newFilePreview() {
        const dialog = this.dialogService.open(FilePickerComponent, {
            width: '500px',
            height: '300px',
            data: { showHeaderRowInput: false },
        });
        dialog.onClose.subscribe((res: { file: File; sheetName: string }) => {
            if (!res) return;
            this.mappingService
                .readFileData(res.file, res.sheetName)
                .subscribe((done) => {
                    if (!done) return;
                    this.preview();
                });
        });
    }

    preview() {
        if (!this.mappingService.currentFileData) {
            this.newFilePreview();
        } else {
            const success = this.mappingService.mapData(this.mapping);
            if (success) this.mappingService.previewData();
        }
    }

    cancel() {
        this.location.back();
    }
}
