<div class="d-flex flex-row justify-content-center align-items-start">
  <div *ngIf="!twoFactor">
    <div class="card shadow-lg">
      <div class="card-header">Login</div>
      <div class="card-body">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="d-flex flex-row align-items-bottom" style="height: 90px">
            <label for="usernameInput" class="col-form-label flex-grow-1"
              >Username
              <input
                type="email"
                [ngClass]="{
                  'form-control': true,
                  'is-invalid':
                    form.controls['username'].invalid &&
                    (form.controls['username'].dirty ||
                      form.controls['username'].touched)
                }"
                id="usernameInput"
                aria-describedby="usernameHelp"
                formControlName="username"
              />
              <ng-container
                *ngIf="
                  form.controls['username'].invalid &&
                  (form.controls['username'].dirty ||
                    form.controls['username'].touched)
                "
              >
                <div
                  class="invalid-feedback"
                  *ngIf="form.controls['username'].errors?.['required']"
                >
                  Username required.
                </div>
              </ng-container></label
            >
          </div>
          <div class="d-flex flex-row align-items-bottom" style="height: 100px">
            <label for="passwordInput" class="col-form-label flex-grow-1"
              >Password
              <input
                type="password"
                [ngClass]="{
                  'form-control': true,
                  'is-invalid':
                    form.controls['password'].invalid &&
                    (form.controls['password'].dirty ||
                      form.controls['password'].touched)
                }"
                id="passwordInput"
                formControlName="password"
              />
              <ng-container
                *ngIf="
                  form.controls['password'].invalid &&
                  (form.controls['password'].dirty ||
                    form.controls['password'].touched)
                "
              >
                <div
                  class="invalid-feedback"
                  *ngIf="form.controls['password'].errors?.['required']"
                >
                  Password required.
                </div>
              </ng-container>
            </label>
          </div>
          <div class="d-flex flex-row align-items-bottom">
            <input type="checkbox" (click)="changeVisibility()" />
            &nbsp;Show password
          </div>
          <div class="d-flex flex-row-reverse mt-2">
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="submitting"
            >
              <ng-container *ngIf="submitting; else defaultContent">
                <div
                  class="spinner-border spinner-border-sm"
                  role="status"
                ></div>
                Loading...
              </ng-container>

              <ng-template #defaultContent>Login</ng-template>
            </button>
          </div>
        </form>
        <div
          class="d-flex flex-row justify-content-center align-items-bottom mt-3"
        >
          <p>
            Having trouble signing in?
            <a routerLink="/forgot-password" id="pass"> Forgot Password?</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
