import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AddMappingComponent } from './pages/add-mapping/add-mapping.component';
import { EditMappingComponent } from './pages/edit-mapping/edit-mapping.component';
import { MappingListComponent } from './pages/mapping-list/mapping-list.component';

const routes: Route[] = [
    {
        path: 'list',
        component: MappingListComponent,
    },
    {
        path: 'add',
        component: AddMappingComponent,
    },
    {
        path: ':id/edit',
        component: EditMappingComponent,
    },
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class DataMapperRoutingModule {}
