import { HttpEventType, HttpProgressEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, tap } from 'rxjs';
import {
    IDocumentCategory,
    IFileUpload,
} from 'src/app/modules/core/models/document';
import { DocumentApiService } from 'src/app/modules/core/services/document-api/document-api.service';
import { EventService } from '../../services/event/event.service';
import { ToastService } from '../../services/toast/toast.service';

const PERCENTAGE_CONSTANT = 100;
const COMPLETE = PERCENTAGE_CONSTANT;

@Injectable()
export class UploadDocumentsService {
    private _categories: BehaviorSubject<IDocumentCategory[]> =
        new BehaviorSubject<IDocumentCategory[]>([]);
    private _uploadStatus: BehaviorSubject<number> = new BehaviorSubject<number>(
        0,
    );

    get categories$(): Observable<IDocumentCategory[]> {
        return this._categories.asObservable();
    }

    constructor(
    private api: DocumentApiService,
    private toast: ToastService,
    private eventService: EventService,
    ) {}

    public getPropertyCategories(propertyId: string): void {
        this.api
            .getPropertyCategories(propertyId)
            .pipe(tap((response) => this._categories.next(response.data)))
            .subscribe();
    }

    public uploadDocuments(
        uploads: IFileUpload[],
        propertyId: string,
    ): Observable<boolean> {
        const fileFormData = new FormData();

        for (let i = 0; i < uploads.length; i++) {
            const upload = uploads[i];
            fileFormData.append('file', upload.file, upload.file.name);
            fileFormData.append('name', upload.name);
            fileFormData.append('categoryId', upload.categoryId);
            fileFormData.append('comment', upload.comment);
            fileFormData.append('status', upload.status);
            fileFormData.append(
                'hasSensitiveInfo',
                upload.hasSensitiveInfo.toString(),
            );
        }

        this.eventService.uploadInProgressNotify({
            fileCount: uploads.length,
            progressSubject: this._uploadStatus,
        });

        return this.api.uploadMultipleDocuments(fileFormData, propertyId).pipe(
            tap((event) => {
                if (event.type === HttpEventType.UploadProgress) {
                    const percentDone = event.total
                        ? Math.round((event.loaded / event.total) * PERCENTAGE_CONSTANT)
                        : 0;
                    this._uploadStatus.next(percentDone);
                }
                if (event.type === HttpEventType.Response) {
                    this._uploadStatus.next(COMPLETE);
                    this.toast.success(event.body.message);
                    const categoryIds = uploads.map((u) => u.categoryId);
                    this.eventService.uploadCompleteNotify({ propertyId, categoryIds });
                }
            }),
            catchError((_) => {
                this._uploadStatus.next(-1);
                throw new Error('Error uploading document.');
            }),
            map((_) => true),
        );
    }
}
