<ng-container *ngIf="organization$ | async as organization">
    <app-org-admin-info
    (submitOrganization)="onSubmit($event)"
    [organization]="organization"
    >
    <ng-container title> Edit User Profile </ng-container>
    <button
        cancelButton
        type="button"
        class="btn btn-secondary me-0 me-md-2"
        (click)="onCancel()"
    >
        Cancel
    </button>
    <button
        submitButton
        type="submit"
        class="btn btn-primary ms-0 ms-2 mt-md-0"
        [disabled]="submitting"
    >
        <ng-container *ngIf="submitting; else default">
        <div class="spinner-border spinner-border-sm" role="status"></div>
        Saving...
        </ng-container>

        <ng-template #default>Save</ng-template>
    </button>
    </app-org-admin-info>
</ng-container>