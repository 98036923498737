import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { Overview } from './overview.model';

const mockdata: Overview[] = [
    { iconUri: 'o', name: 'Busniess One', category: 'Retail', revenue: '$90k' },
    { iconUri: 'o', name: 'Non-Profit B', category: 'Retail', revenue: '$80k' },
    { iconUri: 'o', name: 'Non-Profit C', category: 'Retail', revenue: '$70k' },
];

export class OverviewDataSource extends DataSource<Overview> {
    data = new BehaviorSubject<Overview[]>(mockdata);
    connect(): Observable<Overview[]> {
        return this.data;
    }
    override disconnect(collectionViewer: CollectionViewer): void {}
}
