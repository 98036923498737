import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    DataMapping,
    MappingColumn,
} from 'src/app/modules/core/models/mapping';
import { DialogService } from 'primeng/dynamicdialog';
import { FilePickerComponent } from 'src/app/modules/data-mapper/components/file-picker/file-picker.component';
import { DataMappingService } from '../../services/data-mapping.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-add-mapping',
    templateUrl: './add-mapping.component.html',
    providers: [DataMappingService],
})
export class AddMappingComponent implements OnInit {
    mapping: DataMapping;
    fileColumns: string[] = [];

    constructor(
    private router: Router,
    private dialogService: DialogService,
    public mappingService: DataMappingService,
    private location: Location,
    ) {}

    ngOnInit(): void {}

    updateMapping(mapping: DataMapping) {
        this.mapping = mapping;
    }

    openFilePicker() {
        const dialog = this.dialogService.open(FilePickerComponent, {
            width: '500px',
            height: '300px',
            data: { showHeaderRowInput: true },
        });
        dialog.onClose.subscribe(
            (res: { file: File; sheetName: string; headerRowIndex: number }) => {
                if (!res) return;

                this.mappingService
                    .readFileData(res.file, res.sheetName)
                    .subscribe((done) => {
                        if (!done) return;
                        const headers: string[] =
              this.mappingService.currentFileData[res.headerRowIndex - 1];
                        this.fileColumns = headers.map((header) => header);
                        this.mapping = new DataMapping({
                            headerRowIndex: res.headerRowIndex,
                        });
                        this.mapping.columns.push({
                            name: headers[0],
                            mappedTo: this.mappingService.mappedToColumns[0],
                            required: false,
                        });
                    });
            },
        );
    }

    addColumnsManually() {
        this.mapping = new DataMapping();
        this.mappingService.mappedToColumns.forEach((column, i) => {
            const mappingColumn: MappingColumn = {
                name: `Column ${i + 1}`,
                mappedTo: column,
                required: false,
            };
            this.mapping.columns.push(mappingColumn);
            this.fileColumns.push(`Column ${i + 1}`);
        });
    }

    save() {
        this.mappingService.createMapping(this.mapping).subscribe((_) => {
            this.router.navigateByUrl('/app/data-mapper/list');
        });
    }

    newFilePreview() {
        const dialog = this.dialogService.open(FilePickerComponent, {
            width: '500px',
            height: '300px',
            data: { showHeaderRowInput: false },
        });
        dialog.onClose.subscribe((res: { file: File; sheetName: string }) => {
            if (!res) return;
            this.mappingService
                .readFileData(res.file, res.sheetName)
                .subscribe((done) => {
                    if (!done) return;
                    this.preview();
                });
        });
    }

    preview() {
        if (!this.mappingService.currentFileData) {
            this.newFilePreview();
        } else {
            const success = this.mappingService.mapData(this.mapping);
            if (success) this.mappingService.previewData();
        }
    }

    cancel() {
        this.location.back();
    }
}
