import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, map } from 'rxjs';
import { IApiDataResponse, IApiResponse } from 'src/app/modules/core/models/api-response.interface';
import { ToastService } from 'src/app/modules/core/services/toast/toast.service';
import { OrgAdminApiService } from './org-admin-api.service';
import { Organization } from '../models/organization.model';

@Injectable()
export class EditOrgAdminService {
  private _org: BehaviorSubject<Organization | null> =
    new BehaviorSubject<Organization | null>(null);

  get orgSnapshot(): Organization | null {
    return this._org.value;
  }

  get org$(): Observable<Organization | null> {
    return this._org.asObservable();
  }

  constructor(
    private organizationApi: OrgAdminApiService,
    private toast: ToastService,
  ) {}

  public initializeForOrgAdmin(orgId: string) {
    return this.organizationApi.getOrgAdminById(orgId).pipe(
    map((response: IApiDataResponse<Organization>) => {
        this._org.next(new Organization(response.data));
      }),
    );
  }

  public save(organization: Organization): Observable<boolean> {
    return this.organizationApi.updateOrganization(organization).pipe(
    map((response: IApiResponse) => {
        this.toast.success(response.message);
        return true;
      }),
    );
  }
}