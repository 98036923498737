export enum UserRole {
  Admin = 'admin',
  InternalUser = 'internal user',
  ExternalUser = 'external user',
}

export enum DocumentStatus {
  NotReviewed = 'Not Reviewed',
  UnderReview = 'Under Review',
  Reviewed = 'Reviewed',
  Final = 'Final',
}

export enum CategoryStatus {
  InProgress = 'In Progress',
  Complete = 'Complete',
}

export enum PropertyStatus {
  Active = 'active',
  Closed = 'closed',
}

const MAX_FILE_SIZE_BYTES = 1073741824; // 1 GB
export class Statuses {
    public static documentStatuses: string[] = [
        DocumentStatus.NotReviewed,
        DocumentStatus.UnderReview,
        DocumentStatus.Reviewed,
        DocumentStatus.Final,
    ];

    public static categoryStatuses: string[] = [
        CategoryStatus.InProgress,
        CategoryStatus.Complete,
    ];

    public static propertyStatuses: string[] = [
        PropertyStatus.Active,
        PropertyStatus.Closed,
    ];

    public static maxFileSize: number = MAX_FILE_SIZE_BYTES;
}
