import { Component, OnInit } from '@angular/core';
import { Observable, skipWhile, tap } from 'rxjs';
import { Document, IDocumentCategory } from '../../models/document';
import { DocumentDetailsService } from './document-details.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DocumentVersion } from '../../models/document-version';
import {
    ICopyRequest,
    ISelectionNodeData,
    SelectionNode,
} from '../../models/selection-node';
import { ConfirmationService } from 'primeng/api';
import { UserService } from '../../services/user/user.service';
import { Statuses, UserRole } from 'src/app/modules/shared/statuses';

@Component({
    selector: 'app-document-details',
    templateUrl: './document-details.component.html',
    providers: [DocumentDetailsService],
})
export class DocumentDetailsComponent implements OnInit {
    document: Document;
    docVersions: Observable<DocumentVersion[]>;
    selectionNodes: SelectionNode[];
    selectedNode: SelectionNode;
    selectedStatus: string;
    selectedCategory: IDocumentCategory;
    dataLoading: Observable<boolean>;
    categories: Observable<IDocumentCategory[]>;
    filteredCategories: IDocumentCategory[] = [];
    allowStatusChange: boolean = false;
    UserRole = UserRole;
    maxFileSize = Statuses.maxFileSize;

    constructor(
    private documentSvc: DocumentDetailsService,
    private dialogConfig: DynamicDialogConfig,
    private confirmationSvc: ConfirmationService,
    private dialogRef: DynamicDialogRef,
    public userService: UserService,
    ) {
        this.document = this.dialogConfig.data.document;
        this.allowStatusChange = this.dialogConfig.data.allowStatusChange ?? false;
        this.dataLoading = this.documentSvc.dataLoading$;
        this.categories = this.documentSvc.propertyCategories$;
        this.documentSvc.initData(
            this.document.id,
            this.dialogConfig.data.propertyId,
        );
    }

    ngOnInit(): void {
        this.documentSvc.propertyCategories$.subscribe((res) => {
            this.selectedCategory = {
                ...res.find((r) => r.id === this.document.categoryId),
            };
        });
        this.docVersions = this.documentSvc.documentVersions$;
        this.documentSvc.selectionNodes$
            .pipe(skipWhile((nodes) => nodes.length === 0))
            .subscribe((res) => {
                this.selectionNodes = res;
                for (const n of this.selectionNodes) {
                    const found = n.children.find(
                        (c) =>
                            c.key === this.document.propertyId + this.document.categoryId,
                    );
                    if (found) {
                        this.selectedNode = found;
                        return;
                    }
                }
            });
    }

    cancel() {
        this.dialogRef.close();
    }

    searchCategories(searchText: string) {
        const filtered: IDocumentCategory[] = [];
        const categories = this.documentSvc.propertyCategoriesSnapshot;
        for (let i = 0; i < categories.length; i++) {
            const category = categories[i];
            if (category.name.toLowerCase().indexOf(searchText.toLowerCase()) === 0) {
                filtered.push(category);
            }
        }
        this.filteredCategories = filtered;
    }

    updateStatus(newStatus: string) {
        this.document.status = newStatus;
    }

    save() {
        const updatedDocument = { ...this.document };
        updatedDocument.categoryId = this.selectedCategory.id;
        this.documentSvc
            .saveDocument(updatedDocument)
            .pipe(
                tap((_) => {
                    this.dialogRef.close();
                }),
            )
            .subscribe();
    }

    confirmDelete(event: Event, documentVersionId: string) {
        this.confirmationSvc.confirm({
            key: 'deleteVersion',
            target: event.target,
            message: 'Are you sure you want to delete this version?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.delete(documentVersionId);
            },
        });
    }

    delete(documentVersionId: string) {
        this.documentSvc
            .deleteDocumentVersion(documentVersionId, this.document.id)
            .pipe(
                tap(() => {
                    if (this.documentSvc.documentVersionsSnapshot.length === 0) {
                        this.dialogRef.close();
                    }
                }),
            )
            .subscribe();
    }

    onUpload(files: File[]) {
        const formData = new FormData();
        formData.append(files[0].name, files[0]);
        this.documentSvc
            .uploadNewDocumentVersion(formData, this.document.id)
            .subscribe();
    }

    moveDocument(selection: ISelectionNodeData) {
        const payload = { ...this.document };
        payload.propertyId = selection.propertyId;
        payload.categoryId = selection.categoryId;

        this.documentSvc
            .moveDocument(payload, selection.categoryName)
            .pipe(
                tap((_) => {
                    this.dialogRef.close();
                }),
            )
            .subscribe();
    }

    copyDocument(selection: ISelectionNodeData) {
        const payload: ICopyRequest = {
            fileInfoIdToCopy: this.document.id,
            categoryId: selection.categoryId,
            propertyId: selection.propertyId,
        };
        this.documentSvc.copyDocument(payload, selection.categoryName).subscribe();
    }

    downloadVersion(documentVersionId: string) {
        this.documentSvc.downloadDocumentVersion(documentVersionId).subscribe();
    }
}
