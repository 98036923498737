import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    constructor(private messageSvc: MessageService) {}

    public success(message: string) {
        this.messageSvc.add({
            severity: 'success',
            summary: message,
        });
    }

    public error(message: string) {
        this.messageSvc.add({
            severity: 'error',
            summary: message,
        });
    }

    public custom(message: Message) {
        this.messageSvc.add(message);
    }
}
