<div class="d-flex flex-row justify-content-center align-item-center">
  <div class="card" style="width: 100%">
    <div class="card-body p-0">
      <div
        *ngIf="(categories | async).length > 0"
        class="d-flex flex-column justify-content-start align-items-center"
      >
        <app-file-selector
          [categories]="categories | async"
          [defaultCategoryId]="categoryId"
          [incomingFiles]="incomingFiles"
          (onSelectionChange)="handleSelectionChange($event)"
          class="w-100"
        >
        </app-file-selector>
      </div>
    </div>
  </div>
</div>
<div class="d-flex flex-row-reverse mt-2 pt-1 pb-3 pe-4">
  <button
    type="button"
    class="btn btn-primary ms-0 ms-2 mt-md-0"
    (click)="upload()"
    [disabled]="uploadRequest.length == 0"
  >
    Upload
  </button>
  <button
    cancelButton
    class="btn btn-secondary me-0 me-md-2"
    (click)="cancel()"
  >
    Cancel
  </button>
</div>
