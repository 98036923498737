import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IApiResponse } from 'src/app/modules/core/models/api-response.interface';
import { ToastService } from 'src/app/modules/core/services/toast/toast.service';
import { TeamMember } from '../models/team-member.model';
import { TeamMemberApiService } from './team-member-service';

@Injectable()
export class AddTeamMemberService {
    constructor(
        private teamMemberApi: TeamMemberApiService,
        private toast: ToastService,
    ) {}

    public create(teamMember: TeamMember): Observable<boolean> {
        return this.teamMemberApi.createTeamMember(teamMember).pipe(
            map((response: IApiResponse) => {
                this.toast.success(response.message);
                return true;
            }),
        );
    }
}
