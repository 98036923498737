import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Organization } from '../../org-admin/models/organization.model';
import { TeamMember } from '../models/team-member.model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-team-member-info',
    templateUrl: './team-member-info.component.html',
    styleUrls: ['./team-member-info.component.scss'],
})
export class TeamMemberInfoComponent {
    public form: FormGroup;
    @Input() orgId: number | null;
    @Input() teamMember: TeamMember | null;
    @Input() organizationList: Organization[] | null;
    @Input() submittingAndAdding: boolean = false;
    @Output() submitTeamMember: EventEmitter<{teamMember: TeamMember; saveAndAdd: boolean; orgId: number;}> = 
        new EventEmitter<{teamMember: TeamMember; saveAndAdd: boolean; orgId: number}>();
    public submitting: boolean = false;

    constructor
    (
        private fb: FormBuilder,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            id: [null],
            organizationId: [null, [Validators.required]],
            userId: [null],
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
        });

        if (this.teamMember) {
            this.form.patchValue(this.teamMember);
            this.form.controls['email'].disable();
        } else {
            this.form.patchValue(new TeamMember());
        }
        
        if (this.orgId != null) {
            this.form.patchValue({organizationId: this.orgId});
        }
    }

    public onCancel() {
        this.router.navigate(['/app/team-member']);
    }

    public save() {
        if (!this.form.valid) return;

        const currentTeamMember = new TeamMember(this.form.getRawValue());
        this.submitting = true;
        
        this.submitTeamMember.emit({teamMember: currentTeamMember, saveAndAdd: false, orgId: null});
    }

    public saveAndAdd() {
        if (!this.form.valid) return;

        const currentTeamMember = new TeamMember(this.form.getRawValue());
        this.form.reset();
        this.form.patchValue({organizationId: currentTeamMember.organizationId, id: undefined});
        
        this.submitTeamMember.emit({teamMember: currentTeamMember, saveAndAdd: true, orgId: currentTeamMember.organizationId});
    }
}
