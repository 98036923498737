import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { DataMappingApiService } from 'src/app/modules/core/services/data-mapping-api/data-mapping-api.service';
import { ToastService } from 'src/app/modules/core/services/toast/toast.service';

@Component({
    selector: 'app-mapping-list',
    templateUrl: './mapping-list.component.html',
})
export class MappingListComponent implements OnInit {
    mappings: any[];

    constructor(
    private mappingApi: DataMappingApiService,
    private router: Router,
    private confirmationSvc: ConfirmationService,
    private toastService: ToastService,
    ) {}

    ngOnInit(): void {
        this.fetchMappings();
    }

    fetchMappings() {
        this.mappingApi.getMappings().subscribe((res) => {
            this.mappings = res.data;
        });
    }

    navigateToEditPage(mappingId: string) {
        this.router.navigateByUrl(`/app/data-mapper/${mappingId}/edit`);
    }

    navigateToAddPage() {
        this.router.navigateByUrl('/app/data-mapper/add');
    }

    confirmDelete(event: Event, mappingId: string) {
        this.confirmationSvc.confirm({
            target: event.target,
            message: 'Are you sure that you want to delete?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.deleteMapping(mappingId);
            },
            reject: () => {}, //close popup
        });
    }

    deleteMapping(mappingId: string) {
        this.mappingApi.deleteMapping(mappingId).subscribe((_) => {
            this.toastService.success('Mapping Deleted.');
            this.fetchMappings();
        });
    }
}
