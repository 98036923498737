import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Organization } from '../models/organization.model';
import { Observable, finalize, tap } from 'rxjs';
import { EditOrgAdminService } from '../services/edit-org-admin.service';

@Component({
  selector: 'app-edit-org-admin',
  templateUrl: './edit-org-admin.component.html',
  styleUrls: ['./edit-org-admin.component.scss'],
  providers: [EditOrgAdminService],
})
export class EditOrgAdminComponent implements OnInit {
  public organization$: Observable<Organization | null>;;
  public loading: boolean = false;
  public submitting: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private editOrgAdminSvc: EditOrgAdminService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.organization$ = this.editOrgAdminSvc.org$;
      
      const organizationId = params['id'];
      
      this.loading = true;
      this.editOrgAdminSvc
      .initializeForOrgAdmin(organizationId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe();
    });
  }

  public onSubmit(org: Organization): void {
    const organization = org;

    this.submitting = true;

    this.editOrgAdminSvc
      .save(organization)
      .pipe(
        tap(() => this.router.navigateByUrl('/app/org-admin')),
        finalize(() => (this.submitting = false)),
      )
      .subscribe();
  }

  public onCancel(): void {
    this.location.back();
  }
}
