import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MappingEditorComponent } from './components/mapping-editor/mapping-editor.component';
import { FilePickerComponent } from './components/file-picker/file-picker.component';
import { DataMapperRoutingModule } from './data-mapper-routing.module';
import { AddMappingComponent } from './pages/add-mapping/add-mapping.component';
import { EditMappingComponent } from './pages/edit-mapping/edit-mapping.component';
import { MappingListComponent } from './pages/mapping-list/mapping-list.component';
import { MappingPreviewComponent } from './components/mapping-preview/mapping-preview.component';
import { InputTextModule } from 'primeng/inputtext';

@NgModule({
    declarations: [
        MappingEditorComponent,
        EditMappingComponent,
        AddMappingComponent,
        MappingListComponent,
        FilePickerComponent,
        MappingPreviewComponent,
    ],
    imports: [
        CommonModule,
        DataMapperRoutingModule,
        DropdownModule,
        TableModule,
        DividerModule,
        ButtonModule,
        FileUploadModule,
        FormsModule,
        ReactiveFormsModule,
        TooltipModule,
        CheckboxModule,
        ConfirmPopupModule,
        SplitButtonModule,
        InputTextModule,
    ],
    providers: [DialogService, ConfirmationService],
})
export class DataMapperModule {}
