import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IApiDataResponse } from '../../../models/api-response.interface';
import { TeamMember } from '../models/team-member.model';

@Injectable({
    providedIn: 'root',
})
export class TeamMemberApiService {
    private TEAMMEMBER_API = `${environment.apiBaseURL}Teammember`;

    constructor(private http: HttpClient) {}

    public createTeamMember(teamMember: TeamMember): Observable<IApiDataResponse<TeamMember>> {
        return this.http.post<IApiDataResponse<TeamMember>>(`${this.TEAMMEMBER_API}`, teamMember);
    }

    public getTeamMemberById(id: string): Observable<IApiDataResponse<TeamMember>> {
        return this.http.get<IApiDataResponse<TeamMember>>(`${this.TEAMMEMBER_API}/ById?id=${id}`);
    }

    public updateTeamMember(teamMember: TeamMember): Observable<IApiDataResponse<TeamMember>> {
        return this.http.post<IApiDataResponse<TeamMember>>(`${this.TEAMMEMBER_API}/Update`, teamMember);
    }
}
