import { Component, ViewChild } from '@angular/core';
import { TeamMember } from './models/team-member.model';
import { DisplayColumn } from 'src/app/modules/shared/models/display-column.model';
import { BasicActionTableComponent } from '../../components/action-table/basic-action-table/basic-action-table.component';
import { Action } from '../../components/action-table/models/action';
import { Router } from '@angular/router';
import { OrgAdminApiService } from '../org-admin/services/org-admin-api.service';
import { Organization } from '../org-admin/models/organization.model';

@Component({
    selector: 'app-team-members',
    templateUrl: './team-members.component.html',
    styleUrls: ['./team-members.component.scss'],
})
export class TeamMembersComponent {
    @ViewChild('app-basic-action-table') dataType: BasicActionTableComponent<TeamMember>;
    columns: DisplayColumn[];
    actions: Action[] = [
        { text: '', def: 'dropdown', type: 'dropdown' },
        { text: 'Add New Team Member', def: 'add', type: 'add-btn' },
    ];
    organizationList: Organization[] = null;

    constructor
    (
        private router: Router,
        private orgAdminSvc: OrgAdminApiService
    ) {
        // TODO add logic for true/false param
        this.columns = TeamMember.asDisplayColumns(true);

        this.orgAdminSvc.getOrganizations().subscribe(o => {
            this.organizationList = o.data;
        });

    }

    eventDetected(event: any) {
        if (event.action === 'add' && event.orgId != null) {
            this.router.navigate(['/app/team-member/add', {orgId: event.orgId}]);
        } else if (event.action === 'add') {
            this.router.navigate(['/app/team-member/add']);
        } else if (event.action === 'edit') {
            this.router.navigate([`/app/team-member/${event.id}/edit`]);
        }
    }
}
