import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Organization } from '../models/organization.model';
import { AddOrgAdminService } from '../services/add-org-admin.service';
import { finalize, tap } from 'rxjs';
@Component({
    selector: 'app-add-org-admin',
    templateUrl: './add-org-admin.component.html',
    styleUrls: ['./add-org-admin.component.scss'],
    providers: [AddOrgAdminService],
})
export class AddOrgAdminComponent {
    public submitting: boolean = false;

    constructor(
        private router: Router,
        private location: Location,
        private addOrgAdminSvc: AddOrgAdminService
    ) {}

    public onSubmit(org: Organization): void {
        org.logoImagePath = "path";
        const organization = org;

        this.submitting = true;

        this.addOrgAdminSvc.create(organization).pipe(
            tap(() => this.router.navigateByUrl('/app/org-admin')),
            finalize(() => (this.submitting = false)),
        ).subscribe();
    }

    public onCancel(): void {
        this.location.back();
    }
}
