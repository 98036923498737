import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthAbstractService } from '../../../core/services/auth/auth-abstract.service';
import { Router } from '@angular/router';
import { finalize, tap } from 'rxjs';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
    public form: FormGroup;
    public submitting: boolean = false;

    constructor(
    private authSvc: AuthAbstractService,
    private fb: FormBuilder,
    private router: Router,
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            userName: ['', [Validators.required, Validators.email]],
        });
    }

    public onSubmit(): void {
        if (!this.form.valid) return;

        const userName = this.form.value.userName;
        this.submitting = true;

        this.authSvc
            .forgotPassword(userName)
            .pipe(
                tap(() => this.router.navigateByUrl('/login')),
                finalize(() => { this.submitting = false; }),
            )
            .subscribe();
    }
}
