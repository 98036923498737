import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { IApiDataResponse, IApiResponse } from 'src/app/modules/core/models/api-response.interface';
import { ToastService } from 'src/app/modules/core/services/toast/toast.service';
import { TeamMember } from '../models/team-member.model';
import { TeamMemberApiService } from './team-member-service';

@Injectable()
export class EditTeamMemberService {
    private _teamMember: BehaviorSubject<TeamMember | null> =
        new BehaviorSubject<TeamMember | null>(null);

    get teamMemberSnapshot(): TeamMember | null {
        return this._teamMember.value;
    }

    get teamMember$(): Observable<TeamMember | null> {
        return this._teamMember.asObservable();
    }

    constructor(
        private teamMemberApi: TeamMemberApiService,
        private toast: ToastService,
    ) {}

    public initializeForTeamMember(teamMemberId: string) {
        return this.teamMemberApi.getTeamMemberById(teamMemberId).pipe(
            map((response: IApiDataResponse<TeamMember>) => {
                this._teamMember.next(new TeamMember(response.data));
            }),
        );
    }

    public save(teamMember: TeamMember): Observable<boolean> {
        return this.teamMemberApi.updateTeamMember(teamMember).pipe(
            map((response: IApiResponse) => {
                this.toast.success(response.message);
                return true;
            }),
        );
    }
}