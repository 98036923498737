import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
    constructor(
    private _dialogRef: DialogRef,
    @Inject(DIALOG_DATA) data: any,
    ) {}

    // TODO This component might not be needed
}
