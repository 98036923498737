import { DisplayColumn } from 'src/app/modules/shared/models/display-column.model';

export class Overview {
    iconUri: string;
    name: string;
    category: string;
    revenue: string;

    static asDisplayColumns(): DisplayColumn[] {
        return [
            { title: '', def: 'iconUri', type: 'image' },
            { title: 'Name', def: 'name', type: 'text'},
            { title: 'Category', def: 'category', type: 'text'},
            { title: 'Revenue', def: 'revenue', type: 'number' },
        ];
    }
}
