import { DisplayColumn } from 'src/app/modules/shared/models/display-column.model';

export class Organization {
    id: number;
    logoImagePath: string;
    name: string;
    description: string;
    firstName: string;
    lastName: string;
    status: string;
    emailAddress: string;
    ein: string;
    phoneNumber: string;
    goal1?: string;
    goal2?: string;
    goal3?: string;
    address: string;
    address2?: string;
    city: string;
    state: string;
    zip: string;
    createdDt: Date;
    createdBy: string;
    modifiedDt: Date;
    modifiedBy: string;
    suspendedDt: Date;
    deletedDt: Date;

    constructor(init?: Partial<Organization>) {
        this.id = init?.id;
        this.logoImagePath = init?.logoImagePath ?? '';
        this.name = init?.name ?? '';
        this.firstName = init?.firstName ?? '';
        this.lastName = init?.lastName ?? '';
        this.description = init?.description ?? '';
        this.status = init?.status ?? '';
        this.emailAddress = init?.emailAddress ?? '';
        this.ein = init?.ein ?? '';
        this.phoneNumber = init?.phoneNumber ?? '';
        this.goal1 = init?.goal1 ?? '';
        this.goal2 = init?.goal2 ?? '';
        this.goal3 = init?.goal3 ?? '';
        this.address = init?.address ?? '';
        this.address2 = init?.address2 ?? '';
        this.city = init?.city ?? '';
        this.state = init?.state ?? '';
        this.zip = init?.zip ?? '';
        this.createdDt = init?.createdDt ?? null;
        this.createdBy = init?.createdBy ?? '';
        this.modifiedDt = init?.modifiedDt ?? null;
        this.modifiedBy = init?.modifiedBy ?? '';
        this.suspendedDt = init?.suspendedDt ?? null;
        this.deletedDt = init?.deletedDt ?? null;
    }

    static asDisplayColumns(isActionable: boolean = false): DisplayColumn[] {
        const cols: DisplayColumn[] = [
            { title: 'Name', def: 'name', type: 'text' },
            { title: 'Description', def: 'description', type: 'text' },
            { title: 'Status', def: 'status', type: 'status' },
            { title: 'Email', def: 'emailAddress', type: 'text' },
        ];

        const actionCols: DisplayColumn[] = [
            { title: '', def: 'edit', text: 'Edit', type: 'button' },
            { title: '', def: 'suspend', text: 'Suspend', type: 'button' },
            { title: '', def: 'delete', text: 'Delete', type: 'button' },
        ];

        return isActionable ? cols.concat(actionCols) : cols;
    }

    static getStatus(organization: Organization) {
        if (organization.suspendedDt) {
            return 'Suspended';
        }
        return 'Active';
    }
}
