<ng-container *ngIf="!(dataLoading | async); else spinner">
  <div class="d-flex gx-5 justify-content-md-end justify-content-center">
    <app-nested-selector
      *ngIf="userService.getRole() != UserRole.ExternalUser"
      [options]="selectionNodes"
      label="Move To"
      icon="bi bi-folder-symlink"
      (selectionChange)="moveDocument($event)"
    >
    </app-nested-selector>
    <div class="mx-2"></div>
    <app-nested-selector
      [options]="selectionNodes"
      label="Copy To"
      icon="pi pi-copy"
      (selectionChange)="copyDocument($event)"
    >
    </app-nested-selector>
  </div>

  <div class="container">
    <div class="row p-md-2">
      <div class="col-12 col-md-6">
        <div class="row">
          <span class="col-md-4 text-md-end fw-bold mt-2">Document Name:</span>
          <input
            pInputText
            class="col-md-8"
            type="text"
            required="required"
            [width]="60"
            [(ngModel)]="document.name"
          />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row">
          <span class="col-md-4 text-md-end fw-bold mt-2">Status:</span>
          <app-document-status
            class="col-md-6"
            [editable]="allowStatusChange"
            [status]="document.status"
            (selectionChange)="updateStatus($event)"
          ></app-document-status>
        </div>
      </div>
    </div>
    <div class="row p-md-2">
      <div class="col-12 col-md-6">
        <div class="row">
          <span class="col-md-4 text-md-end fw-bold mt-2">Comment:</span>
          <textarea
            pInputTextarea
            class="col-md-8"
            rows="3"
            cols="30"
            [(ngModel)]="document.comment"
          ></textarea>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row">
          <span class="col-md-4 text-md-end fw-bold my-md-3">Category:</span>
          <p-autoComplete
            [(ngModel)]="selectedCategory"
            [suggestions]="filteredCategories"
            (completeMethod)="searchCategories($event.query)"
            field="name"
            [dropdown]="true"
            [class]="
              !selectedCategory ? 'ng-invalid ng-dirty col-md-6' : 'col-md-6'
            "
          >
            <ng-template let-category pTemplate="item">
              <div>
                <div>
                  {{ category.number }} - {{ category.name | truncate: 20 }}
                </div>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
        <div class="row">
          <span class="col-4 text-end fw-bold mt-2">Sensitive:</span>
          <p-checkbox
            [(ngModel)]="document.hasSensitiveInfo"
            [binary]="true"
            inputId="sensitiveInfo"
            class="mx-1 col-3 mt-2"
          ></p-checkbox>
        </div>
      </div>
      <div class="container pt-3">
        <p-table
          [value]="docVersions | async"
          dataKey="id"
          #dt
          [resizableColumns]="true"
          responsiveLayout="scroll"
          [scrollable]="true"
          scrollHeight="300px"
          scrollDirection="vertical"
          [rowHover]="true"
        >
          <ng-template pTemplate="caption">
            <div class="row">
              <div class="col">
                <h4 style="color: #606060">Versions</h4>
              </div>
              <div
                class="col d-flex justify-content-md-end justify-content-center"
              >
                <p-fileUpload
                  #fubauto
                  mode="basic"
                  name="demo[]"
                  [customUpload]="true"
                  [maxFileSize]="maxFileSize"
                  [multiple]="false"
                  (uploadHandler)="onUpload($event.files)"
                  [auto]="true"
                  chooseLabel="Upload New Version"
                ></p-fileUpload>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th pResizableColumn [width]="60"></th>
              <th pResizableColumn>Document</th>
              <th pResizableColumn>Uploaded By</th>
              <th pResizableColumn>Upload Date</th>
              <th
                pResizableColumn
                *ngIf="userService.getRole() != UserRole.ExternalUser"
              ></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-docVersion>
            <tr>
              <td>
                <a
                  [routerLink]=""
                  (click)="downloadVersion(docVersion.id)"
                  target="_blank"
                >
                  <button
                    type="button"
                    title="Download"
                    pButton
                    pRipple
                    class="p-button-text p-button-rounded p-button-plain p-mr-2"
                    [icon]="'pi pi-cloud-download'"
                  ></button>
                </a>
              </td>
              <td>{{ docVersion.filename }}</td>
              <td>
                {{ docVersion.uploadedBy?.firstName || '' }}
                {{ docVersion.uploadedBy?.lastName || '' }}
              </td>
              <td>{{ docVersion.uploadDate | date }}</td>
              <td
                style="width: 90px"
                *ngIf="userService.getRole() != UserRole.ExternalUser"
              >
                <p-confirmPopup key="deleteVersion"></p-confirmPopup>
                <button
                  pTooltip="Delete"
                  class="btn delete"
                  (click)="confirmDelete($event, docVersion.id)"
                >
                  <i class="bi bi-trash3-fill"></i>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #spinner>
  <div class="d-flex flex-row justify-content-center" style="min-height: 400px">
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-template>

<div
  class="d-flex mt-2 p-dialog-footer justify-content-md-end justify-content-center"
>
  <button
    type="button"
    class="btn btn-primary ms-0 ms-2 mt-md-0"
    [disabled]="document.name === ''"
    (click)="save()"
  >
    Save
  </button>
  <button
    cancelButton
    class="btn btn-secondary me-0 me-md-2"
    (click)="cancel()"
  >
    Cancel
  </button>
</div>
