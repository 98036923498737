<header>
  <nav class="navbar shadow-sm justify-content-center">
    <div class="d-flex flex-row justify-content-center">
      <img class="image" src="assets/flowar-logo.png" />
    </div>
  </nav>
</header>

<main
  class="container py-5 d-flex justify-content-center"
  style="align-items: center"
>
  <!-- Remove py-5 when adding a logo. -->
  <router-outlet></router-outlet>
</main>
