import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Message, MessageService } from 'primeng/api';
import { IApiResponse } from '../models/api-response.interface';
import { LoginResponseStatus } from '../services/auth/auth.service';
import { AuthAbstractService } from '../services/auth/auth-abstract.service';
import { HTTP_CLIENT_ERRORS, HTTP_SERVER_ERRORS } from '../../shared/constants';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
    private messages: MessageService,
    private injector: Injector,
    ) {}

    public intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((res: HttpErrorResponse) => {
                const apiError = res.error as IApiResponse;
                const toastMessage: Message = {
                    severity: 'error',
                    summary: apiError?.message ?? 'ERROR',
                };
                if (!res.ok) {
                    switch (res.status) {
                        case LoginResponseStatus.PasswordExpired:
                            this.messages.add(toastMessage);
                            return next.handle(request);
                            // TODO: Move Http Statuses to separate constants file
                        case HTTP_CLIENT_ERRORS.BAD_REQUEST:
                            this.messages.add(toastMessage);
                            return throwError(() => new Error(apiError?.message));

                        case HTTP_CLIENT_ERRORS.UNAUTHORIZED: {
                            toastMessage.summary =
                'User authentication expired, please log back in.';
                            this.messages.add(toastMessage);
                            this.injector.get(AuthAbstractService).logout().subscribe();
                            return throwError(() => new Error(apiError?.message));
                        }
                        case HTTP_CLIENT_ERRORS.FORBIDDEN:
                            toastMessage.summary =
                'User requested a forbidden action, please log back in to verify user account and permissions.';
                            this.messages.add(toastMessage);
                            this.injector.get(AuthAbstractService).logout().subscribe();
                            return next.handle(request);

                        case HTTP_CLIENT_ERRORS.NOT_FOUND:
                            toastMessage.summary =
                'Not Found. Please report this event to your Admin.';
                            this.messages.add(toastMessage);
                            return throwError(() => new Error(apiError?.message));

                        case HTTP_SERVER_ERRORS.INTERNAL_SERVER_ERROR:
                            toastMessage.summary =
                'A 500 error was encountered, please report this event to your Admin.';
                            this.messages.add(toastMessage);
                            return throwError(() => new Error(apiError?.message));

                        case HTTP_SERVER_ERRORS.SERVICE_UNAVAILABLE:
                            toastMessage.summary =
                'A 503 error was encountered, please report this event to your Admin.';
                            this.messages.add(toastMessage);
                            return throwError(() => new Error(apiError?.message));

                        default:
                            toastMessage.summary = `Unexpected error code received: ${res.status}`;
                            this.messages.add(toastMessage);
                            return throwError(() => new Error(apiError?.message));
                    }
                }
                return next.handle(request);
            }),
        );
    }
}
