<app-org-admin-info (submitOrganization)="onSubmit($event)">
    <ng-container title> Add New Organization </ng-container>
    <button
        cancelButton
        type="button"
        class="btn btn-secondary btn-lg me-2"
        (click)="onCancel()"
    >
        Cancel
    </button>
    <button
        submitButton
        type="submit"
        class="btn btn-primary btn-lg ms-2"
        [disabled]="submitting"
    >
        <ng-container *ngIf="submitting; else default">
            <div class="spinner-border spinner-border-sm" role="status"></div>
            Creating...
        </ng-container>
        <ng-template #default>Create</ng-template>
    </button>
</app-org-admin-info>
