import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreatePasswordRequest } from '../../models/create-password-request';
import { LoginRequest } from '../../models/login-request.interface';
import { ResetPasswordRequest } from '../../models/reset-password-request.interface';
import { ILoginResponse } from './auth.service';

@Injectable()
export abstract class AuthAbstractService {
  abstract login(loginRequestObject: LoginRequest): Observable<ILoginResponse>;
  abstract logout(showToast?: boolean): Observable<boolean>;
  abstract forgotPassword(userName: string): Observable<boolean>;
  abstract resetPassword(
    resetPasswordRqt: ResetPasswordRequest,
  ): Observable<boolean>;
  abstract register(
    createPasswordRqt: CreatePasswordRequest,
  ): Observable<boolean>;
  abstract authenticated(): boolean;
  abstract checkAuthForRoute(): Observable<boolean>;
  abstract impersonate(id: string): void;
  abstract isImpersonating(): boolean;
  abstract stopImpersonating(): void;
}
