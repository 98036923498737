import { Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-mapping-preview',
    template: `
    <p-table [value]="data" [paginator]="true" [rows]="20">
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let header of headers">
            {{ header }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td *ngFor="let header of headers">
            {{ rowData[header] }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  `,
})
export class MappingPreviewComponent {
    data: any[];
    headers: string[];

    constructor(private dialogConfig: DynamicDialogConfig) {
        this.data = this.dialogConfig.data.data;
        this.headers = this.dialogConfig.data.headers;
    }
}
