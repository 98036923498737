<p-fileUpload
  name="fileSelector"
  mode="advanced"
  cancelLabel="Clear All"
  chooseLabel="Documents"
  [files]="incomingFiles"
  [maxFileSize]="maxFileSize"
  [fileLimit]="fileLimit"
  [customUpload]="true"
  [multiple]="true"
  [showUploadButton]="false"
  (onSelect)="selectFiles($event.files)"
  (onClear)="clearSelectedFiles()"
>
  <ng-template let-file pTemplate="file"></ng-template>
  <ng-template pTemplate="content" let-files>
    <div
      style="max-height: 45vh; overflow-y: auto; overflow-x: hidden"
      class="w-100"
    >
      <div *ngFor="let item of selectedList; let i = index" class="w-90">
        <div class="row ms-1 justify-space-between">
          <div class="col-8 mb-1 bold">{{ item.file.name }}</div>
          <p-checkbox
            [(ngModel)]="item.hasSensitiveInfo"
            (onChange)="emitSelectionChange()"
            label="Sensitive"
            [binary]="true"
            inputId="sensitiveInfo"
            class="mx-1 col-3"
          >
          </p-checkbox>
        </div>
        <div class="row ms-1">
          <div class="col-11">
            <div class="d-flex justify-content-between w-100">
              <input
                pInputText
                type="text"
                class="w-100 mx-1"
                required="required"
                [(ngModel)]="item.name"
                (blur)="emitSelectionChange()"
              />
              <p-dropdown
                appendTo="body"
                [options]="categories"
                optionLabel="name"
                optionValue="id"
                [(ngModel)]="item.categoryId"
                (onChange)="emitSelectionChange()"
                class="mx-1"
                [filter]="true"
                filterBy="name"
                scrollHeight="500px"
                [resetFilterOnHide]="true"
              >
                <ng-template let-category pTemplate="item">
                  {{ category.name | truncate: 60 }}
                </ng-template>
                <ng-template pTemplate="selectedItem" let-selectedItem>
                  {{ selectedItem.name | truncate: 20 }}
                </ng-template>
              </p-dropdown>
              <p-dropdown
                appendTo="body"
                [options]="statusOptions"
                (onChange)="emitSelectionChange()"
                [(ngModel)]="item.status"
                class="mx-1"
              >
              </p-dropdown>
            </div>
            <div class="row mt-2 mx-1">
              <input
                pInputText
                type="text"
                class="w-100"
                (blur)="emitSelectionChange()"
                [(ngModel)]="item.comment"
                placeholder="Enter comments here"
              />
            </div>
          </div>
          <div class="col-1 d-flex justify-content-center align-items-center">
            <button
              pButton
              pRipple
              (click)="onRemoveFile(i)"
              type="button"
              icon="pi pi-trash"
              label=""
              class="p-button-danger p-button-text"
            ></button>
          </div>
        </div>
        <p-divider></p-divider>
      </div>
    </div>
    <div>
      <i
        class="pi pi-upload d-flex justify-content-center"
        style="font-size: 2rem; color: lightgray"
      ></i>
      <span class="d-flex justify-content-center"
        >Choose files or drag them here.</span
      >
    </div>
  </ng-template>
</p-fileUpload>
