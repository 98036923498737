import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';

export class TDataSource<T> extends DataSource<T> {
    // TODO add constructor to accept data, remove default
    data = new BehaviorSubject<T[]>(null);
    private initialData: T[];
    public static defaultComparator<T>(item: T, value: string) {
        return JSON.stringify(Object.values(item)).toLowerCase().includes(value);
    }

    constructor(data: T[]) {
        super();
        this.data = new BehaviorSubject<T[]>(data);
        this.initialData = data;
    }

    connect(): Observable<T[]> {
        return this.data;
    }
    override disconnect(collectionViewer: CollectionViewer): void {}

    filter(value: string, comparator?: (item:T, value:string) => boolean) {
        if (!value) {
            this.data.next(this.initialData);
            return;
        }

        const normalized_value = value.toLowerCase();
        const filteredData = this.initialData.filter(item => {
            if (comparator) {
                return comparator(item, normalized_value);
            } else {
                return TDataSource.defaultComparator(item, normalized_value);
            }
        });
        this.data.next(filteredData);
    }

    length() {
        return this.initialData.length;
    }
}
