import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DisplayColumn } from 'src/app/modules/shared/models/display-column.model';
import { BasicActionTableService } from './basic-action-table.service';
import { TDataSource } from 'src/app/modules/shared/models/data-source';
import { Action } from '../models/action';
import { finalize } from 'rxjs';
import { Organization } from '../../../pages/org-admin/models/organization.model';

/**
 * A Basic wrapper for ActionTable
 * Made to accept an endpoint string to be able to handle the
 * data operations in a steamlined way.
 */
@Component({
    selector: 'app-basic-action-table',
    templateUrl: './basic-action-table.component.html',
    styleUrls: ['./basic-action-table.component.scss'],
    template: '<app-basic-action-table #dataType></app-basic-action-table>'
})
export class BasicActionTableComponent<T> implements OnInit {
    @ViewChild('modal') modal: any;
    @Input() endpoint: string;
    @Input() dataName: string = 'Item';
    @Input() columns: DisplayColumn[];
    @Input() actions: Action[];
    @Input() organizations: Organization[];
    @Input() filterComparator?: (item: T, value: string) => boolean;
    @Output() actionEvent: EventEmitter<{action:string, id:string}> = new EventEmitter<{action:string, id:string}>();

    dataSource: TDataSource<T>;
    dialogVisible: boolean = false;
    submitting: boolean = false;
    deletionId: number;

    constructor
    (
        private _dataService: BasicActionTableService<T>
    ) {}

    ngOnInit() {
        this.getData();
    }

    getData() {
        if (this.endpoint && this.organizations == null) {
            this._dataService.get(this.endpoint).subscribe({
                next: (data: any) => {
                    this._dataService.dataSource$.subscribe(data =>
                        this.dataSource = new TDataSource(data)
                    );
                },
                error: () => {},
            });
        }
    }

    eventDetected(event: any) {
        if (event.action === 'suspend') {
            this._dataService.suspend(this.endpoint, event.id).subscribe({
                next: (data: any) => {
                    this._dataService.dataSource$.subscribe(data => {
                        this.dataSource = new TDataSource(data);
                    });
                },
                error: () => {},
            });
        } else if (event.action === 'delete') {
            this.dialogVisible = true;
            this.deletionId = event.id;
        } else if (event.action === 'dropdown') {
            this._dataService.TeamMembersByOrganization(this.endpoint, event.id).subscribe({
                next: (data: any) => {
                    this._dataService.dataSource$.subscribe(data => {
                        this.dataSource = new TDataSource(data);
                    });
                },
                error: () => {},
            });
        } else {
            this.actionEvent.emit(event);
        }
    }

    submitDeletion() {
        this.submitting = true;
        this._dataService.delete(this.endpoint, this.deletionId)
            .pipe(
                finalize(() => {
                    this.submitting = false
                    this.dialogVisible = false;
                }))
            .subscribe({
                next: (data: any) => {
                    this._dataService.dataSource$.subscribe(data => {
                        this.dataSource = new TDataSource(data);
                    });
                },
                error: () => {},
            }
            );
    }
}
