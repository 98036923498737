import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { SimplePageLayoutComponent } from 'src/app/modules/core/layouts/simple-page-layout/simple-page-layout.component';
import { LoginGuard } from '../core/guards/login.guard';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login/login.component';
import { RefreshPasswordComponent } from './pages/refresh-password/refresh-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { UserInvitationComponent } from './pages/user-invitation/user-invitation.component';

const routes: Route[] = [
    {
        path: '',
        component: SimplePageLayoutComponent,
        children: [
            {
                path: 'login',
                canActivate: [LoginGuard],
                component: LoginComponent,
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent,
            },
            {
                path: 'reset-password',
                component: ResetPasswordComponent,
            },
            {
                path: 'user-invitation',
                component: UserInvitationComponent,
            },
            {
                path: 'reset-expired-password',
                component: RefreshPasswordComponent,
            },
        ],
    },
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthFlowRoutingModule {}
