<div class="d-flex flex-row justify-content-center align-item-center">
  <div class="card shadow-sm">
    <div class="card-header">Data Mapping</div>
    <div class="card-body" *ngIf="mapping != null">
      <app-mapping-editor
        [mapping]="mapping"
        [mappedToColumns]="mappingService.mappedToColumns"
        [fileColumns]="fileColumns"
        (onMappingChange)="updateMapping($event)"
        (onPreview)="preview()"
        (onNewFilePreview)="newFilePreview()"
        (onCancel)="cancel()"
        (onSave)="save()"
      ></app-mapping-editor>
    </div>
  </div>
</div>
