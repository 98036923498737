import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, tap } from 'rxjs';
import { Organization } from '../../org-admin/models/organization.model';
import { OrgAdminApiService } from '../../org-admin/services/org-admin-api.service';
import { AddTeamMemberService } from '../services/add-team-member.service';

@Component({
    selector: 'app-add-team-member',
    templateUrl: './add-team-member.component.html',
    styleUrls: ['./add-team-member.component.scss'],
    providers: [AddTeamMemberService],
})
export class AddTeamMemberComponent {
    public organizationList: Organization[];
    public selectedOrg: number;
    public loading: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private orgAdminSvc: OrgAdminApiService,
        private addTeamMemberSvc: AddTeamMemberService
    ) {
        this.orgAdminSvc.getOrganizations().subscribe(o => {
            this.organizationList = o.data;
        });
        
        if (this.route.snapshot.paramMap.get('orgId') != null) {
            this.selectedOrg = Number(this.route.snapshot.paramMap.get('orgId'));
        }
    }

    public onSubmit(event: any): void {
        const teamMember = event.teamMember;

        if (event.saveAndAdd) {
            this.loading = true;
            this.addTeamMemberSvc.create(teamMember).pipe(
                tap(() => this.router.navigateByUrl('/app/team-member/add')),
                finalize(() => { this.loading = false; })).subscribe();
        } else {
            this.addTeamMemberSvc.create(teamMember).pipe(
                tap(() => this.router.navigateByUrl('/app/team-member'))).subscribe();
        }
    }
}
