<div class="d-flex flex-row align-items-start">
  <label for="role" class="col-form-label flex-grow-1">Roles: </label>
</div>
<div class="row">
  <div class="d-flex flex-wrap align-items-center my-2">
    <div class="form-check form-check-inline" *ngFor="let role of roles">
      <input
        class="form-check-input"
        type="radio"
        id="{{ role.name }}Checkbox"
        [checked]="selectedRole == role.name"
        (click)="onRoleSelect(role.name)"
      />
      <label class="form-check-label">
        {{ role.name }}
      </label>
    </div>
  </div>
</div>
