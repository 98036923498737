import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-file-picker',
    templateUrl: './file-picker.component.html',
})
export class FilePickerComponent implements OnInit {
    currentSheetNames: string[];
    currentFile: File;
    incomingFiles: File[] = [];
    selectedSheetName: string;
    headerRowIndex: number = 1;
    showHeaderRowInput: boolean;

    constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    ) {}

    ngOnInit(): void {
        this.showHeaderRowInput = this.dialogConfig.data.showHeaderRowInput;
    }

    close() {
        const res = {
            file: this.currentFile,
            sheetName: this.selectedSheetName,
            headerRowIndex: this.headerRowIndex,
        };
        this.dialogRef.close(res);
    }

    selectFiles(files: File[]) {
        if (files.length !== 1) throw new Error('Cannot use multiple files');
        this.currentFile = files[0];

        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            // const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
            // this.currentSheetNames = wb.SheetNames;
        };
        reader.readAsBinaryString(files[0]);
    }

    clearSelectedFiles() {
        this.currentFile = null;
    }
}
