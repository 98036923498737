<div class="d-flex flex-row justify-content-center align-item-center">
    <div class="card shadow-sm" style="width: 42rem">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-start align-items-center">
                <div class="title">
                    <ng-content select="[title]"></ng-content>
                </div>
                <form
                    [formGroup]="form"
                    style="width: 100%"
                    (ngSubmit)="onSubmitOrganization()"
                >
                    <div class="d-flex flex-row align-items-center">
                        <img *ngIf="orgPic != null" class="img-thumbnail me-3" width="150rem" [src]="orgPic"/>
                        <label for="logoImagePath" class="col-form-label flex-grow-1"
                        >
                            <p-fileUpload
                                mode="basic"
                                chooseLabel="Upload File From Computer" 
                                name="image"
                                [customUpload]="true"
                                (onSelect)="imageUploader($event)"
                                [multiple]="false"
                                [showUploadButton]="false"
                            ></p-fileUpload>
                        </label>
                    </div>
                    <div class="d-flex flex-row align-items-bottom">
                        <label for="name" class="col-form-label flex-grow-1"
                        >Organization Name
                            <input
                                type="text"
                                id="name"
                                formControlName="name"
                                placeholder="Enter Organization Name"
                                [ngClass]="{
                                    'form-control': true,
                                    'is-invalid':
                                    form.controls['name'].invalid &&
                                    (form.controls['name'].dirty ||
                                    form.controls['name'].touched)
                                }"
                            />
                            <ng-container
                                *ngIf="
                                    form.controls['name'].invalid &&
                                    (form.controls['name'].dirty ||
                                    form.controls['name'].touched)
                                "
                            >
                                <div
                                    class="invalid-feedback"
                                    *ngIf="form.controls['name'].errors?.['required']"
                                > Please enter an organization name.
                                </div>
                            </ng-container>
                        </label>
                    </div>
                    <div class="d-flex flex-row justify-content-between">
                        <div class="d-flex flex-row align-items-bottom flex-fill me-5">
                            <label for="firstName" class="col-form-label flex-grow-1 p-fluid"
                            >First Name
                                <input
                                    type="text"
                                    id="firstName"
                                    formControlName="firstName"
                                    placeholder="Enter First Name"
                                    [ngClass]="{
                                        'form-control': true,
                                        'is-invalid':
                                        form.controls['firstName'].invalid &&
                                        (form.controls['firstName'].dirty ||
                                        form.controls['firstName'].touched)
                                    }"
                                />
                                <ng-container
                                    *ngIf="
                                        form.controls['firstName'].invalid &&
                                        (form.controls['firstName'].dirty ||
                                        form.controls['firstName'].touched)
                                    "
                                >
                                    <div
                                        class="invalid-feedback"
                                        *ngIf="form.controls['firstName'].errors?.['required']"
                                    > Please enter a first name.
                                    </div>
                                </ng-container>
                            </label>
                        </div>
                        <div class="d-flex flex-row align-items-bottom flex-fill">
                            <label for="lastName" class="col-form-label flex-grow-1 p-fluid"
                            >Last Name
                                <input
                                    type="text"
                                    id="lastName"
                                    formControlName="lastName"
                                    placeholder="Enter Last Name"
                                    [ngClass]="{
                                        'form-control': true,
                                        'is-invalid':
                                        form.controls['lastName'].invalid &&
                                        (form.controls['lastName'].dirty ||
                                        form.controls['lastName'].touched)
                                    }"
                                />
                                <ng-container
                                    *ngIf="
                                        form.controls['lastName'].invalid &&
                                        (form.controls['lastName'].dirty ||
                                        form.controls['lastName'].touched)
                                    "
                                >
                                    <div
                                        class="invalid-feedback"
                                        *ngIf="form.controls['lastName'].errors?.['required']"
                                    > Please enter a last name.
                                    </div>
                                </ng-container>
                            </label>
                        </div>
                    </div>
                    <div class="d-flex flex-row align-items-bottom">
                        <label for="ein" class="col-form-label flex-grow-1 p-fluid"
                        >EIN
                            <p-inputMask
                                mask="99-9999999"
                                placeholder="99-9999999"
                                formControlName="ein"
                                [unmask]="true"
                                [ngClass]="{
                                    'is-invalid': form.controls['ein'].invalid
                                }"
                            > </p-inputMask>
                            <ng-container
                                *ngIf="
                                    form.controls['ein'].invalid &&
                                    (form.controls['ein'].dirty ||
                                    form.controls['ein'].touched)
                                "
                            >
                                <div
                                    class="invalid-feedback"
                                    *ngIf="form.controls['ein'].errors?.['required']"
                                > Please enter an EIN.
                                </div>
                            </ng-container>
                        </label>
                    </div>
                    <div class="d-flex flex-row justify-content-between">
                        <div class="d-flex flex-row align-items-bottom flex-fill me-5">
                            <label for="emailAddress" class="col-form-label flex-grow-1 p-fluid"
                            >Email
                                <input
                                    type="email"
                                    id="emailAddress"
                                    formControlName="emailAddress"
                                    placeholder="Enter Email"
                                    [ngClass]="{
                                        'form-control': true,
                                        'is-invalid':
                                        form.controls['emailAddress'].invalid &&
                                        (form.controls['emailAddress'].dirty ||
                                        form.controls['emailAddress'].touched)
                                    }"
                                />
                                <ng-container
                                    *ngIf="
                                        form.controls['emailAddress'].invalid &&
                                        (form.controls['emailAddress'].dirty ||
                                        form.controls['emailAddress'].touched)
                                    "
                                >
                                    <div
                                        class="invalid-feedback"
                                        *ngIf="form.controls['emailAddress'].errors?.['required']"
                                    > Please enter an email.
                                    </div>
                                    <div
                                        class="invalid-feedback"
                                        *ngIf="form.controls['emailAddress'].errors?.['emailAddress']"
                                    > Please enter a valid email.
                                    </div>
                                </ng-container>
                            </label>
                        </div>
                        <div class="d-flex flex-row align-items-bottom">
                            <label for="phoneNumber" class="col-form-label flex-grow-1 p-fluid"
                            >Phone Number
                                <p-inputMask
                                    mask="(999) 999-9999"
                                    placeholder="(999) 999-9999"
                                    formControlName="phoneNumber"
                                    [unmask]="true"
                                    [ngClass]="{
                                        'is-invalid': 
                                        form.controls['phoneNumber'].invalid &&
                                        (form.controls['phoneNumber'].dirty ||
                                        form.controls['phoneNumber'].touched)
                                    }"
                                > </p-inputMask>
                                <ng-container
                                    *ngIf="
                                        form.controls['phoneNumber'].invalid &&
                                        (form.controls['phoneNumber'].dirty ||
                                        form.controls['phoneNumber'].touched)
                                    "
                                >
                                    <div
                                        class="invalid-feedback"
                                        *ngIf="form.controls['phoneNumber'].errors?.['required']"
                                    > Please enter a phone number.
                                    </div>
                                </ng-container>
                            </label>
                        </div>
                    </div>
                    <div class="d-flex flex-row align-items-bottom">
                        <label for="description" class="col-form-label flex-grow-1"
                        >Description of Cause
                            <input
                                type="text"
                                id="description"
                                formControlName="description"
                                placeholder="Enter Description"
                                [ngClass]="{
                                    'form-control': true, 
                                    'is-invalid':
                                    form.controls['description'].invalid &&
                                    (form.controls['description'].dirty ||
                                        form.controls['description'].touched)
                                }"
                            />
                            <ng-container
                                *ngIf="
                                    form.controls['description'].invalid &&
                                    (form.controls['description'].dirty ||
                                    form.controls['description'].touched)
                                "
                            >
                                <div
                                    class="invalid-feedback"
                                    *ngIf="form.controls['description'].errors?.['required']"
                                > Please enter a description.
                                </div>
                            </ng-container>
                        </label>
                    </div>
                    <div class="d-flex flex-row align-items-bottom">
                        <label class="col-form-label flex-grow-1"
                        > What will the proceeds be going towards? (Enter up to 3 items)
                            <div class="col-6">
                                <input
                                    type="text"
                                    id="Item1"
                                    formControlName="goal1"
                                    placeholder="Enter Item1"
                                    class="form-control mb-2"
                                />
                                <input
                                    type="text"
                                    id="Item2"
                                    formControlName="goal2"
                                    placeholder="Enter Item2"
                                    class="form-control mb-2"
                                />
                                <input
                                    type="text"
                                    id="Item3"
                                    formControlName="goal3"
                                    placeholder="Enter Item3"
                                    class="form-control"
                                />
                            </div>
                        </label>
                    </div>
                    <div class="d-flex flex-row align-items-bottom">
                        <label for="address" class="col-form-label flex-grow-1"
                        >Address
                            <input
                                type="text"
                                id="address"
                                formControlName="address"
                                placeholder="Enter Address"
                                [ngClass]="{
                                    'form-control': true,
                                    'is-invalid':
                                    form.controls['address'].invalid &&
                                    (form.controls['address'].dirty ||
                                        form.controls['address'].touched)
                                }"
                            />
                            <ng-container
                                *ngIf="
                                    form.controls['address'].invalid &&
                                    (form.controls['address'].dirty ||
                                    form.controls['address'].touched)
                                "
                            >
                                <div
                                    class="invalid-feedback"
                                    *ngIf="form.controls['address'].errors?.['required']"
                                > Please enter an organization address.
                                </div>
                            </ng-container>
                        </label>
                    </div>
                    <div class="d-flex flex-row align-items-bottom">
                        <label for="address2" class="col-form-label flex-grow-1"
                        >Address2
                            <input
                                type="text"
                                id="address2"
                                formControlName="address2"
                                placeholder="Enter Address2"
                                class="form-control"
                            />
                        </label>
                    </div>
                    <div class="d-flex flex-row align-items-bottom">
                        <label for="city" class="col-form-label "
                        >City
                            <input
                                type="text"
                                id="city"
                                formControlName="city"
                                placeholder="Enter City"
                                [ngClass]="{
                                    'form-control': true,
                                    'is-invalid':
                                    form.controls['city'].invalid &&
                                    (form.controls['city'].dirty ||
                                        form.controls['city'].touched)
                                }"
                            />
                            <ng-container
                                *ngIf="
                                    form.controls['city'].invalid &&
                                    (form.controls['city'].dirty ||
                                    form.controls['city'].touched)
                                "
                            >
                                <div
                                    class="invalid-feedback"
                                    *ngIf="form.controls['city'].errors?.['required']"
                                > Please enter a city.
                                </div>
                            </ng-container>
                        </label>
                    </div>
                    <div class="d-flex flex-row align-items-bottom">
                        <label for="state" class="col-form-label "
                        >State
                            <input
                                type="text"
                                id="state"
                                formControlName="state"
                                placeholder="Enter State"
                                [ngClass]="{
                                    'form-control': true,
                                    'is-invalid':
                                    form.controls['state'].invalid &&
                                    (form.controls['state'].dirty ||
                                        form.controls['state'].touched)
                                }"
                            />
                            <ng-container
                                *ngIf="
                                    form.controls['state'].invalid &&
                                    (form.controls['state'].dirty ||
                                    form.controls['state'].touched)
                                "
                            >
                                <div
                                    class="invalid-feedback"
                                    *ngIf="form.controls['state'].errors?.['required']"
                                > Please enter a state.
                                </div>
                            </ng-container>
                        </label>
                    </div>
                    <div class="d-flex flex-row align-items-bottom">
                        <label for="zip" class="col-form-label "
                        >Zip Code
                            <input
                                type="text"
                                id="zip"
                                formControlName="zip"
                                placeholder="Enter Zip"
                                [ngClass]="{
                                    'form-control': true,
                                    'is-invalid':
                                    form.controls['zip'].invalid &&
                                    (form.controls['zip'].dirty ||
                                        form.controls['zip'].touched)
                                }"
                            />
                            <ng-container
                                *ngIf="
                                    form.controls['zip'].invalid &&
                                    (form.controls['zip'].dirty ||
                                    form.controls['zip'].touched)
                                "
                            >
                                <div
                                    class="invalid-feedback"
                                    *ngIf="form.controls['zip'].errors?.['required']"
                                > Please enter a zip code.
                                </div>
                            </ng-container>
                        </label>
                    </div>
                    <br>
                    <div class="d-flex justify-content-center mt-2">
                        <ng-content select="[cancelButton]"></ng-content>
                        <ng-content select="[submitButton]"></ng-content>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>