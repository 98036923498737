import { Component, ViewChild } from '@angular/core';
import { Organization } from './models/organization.model';
import { DisplayColumn } from 'src/app/modules/shared/models/display-column.model';
import { Action } from '../../components/action-table/models/action';
import { Router } from '@angular/router';
import { BasicActionTableComponent } from '../../components/action-table/basic-action-table/basic-action-table.component';

@Component({
    selector: 'app-org-admin',
    templateUrl: './org-admin.component.html',
    styleUrls: ['./org-admin.component.scss'],
})
export class OrgAdminComponent {
    @ViewChild('app-basic-action-table') dataType: BasicActionTableComponent<Organization>;
    columns: DisplayColumn[];
    actions: Action[] = [
        { text: '', def: 'filter', type: 'filter' },
        { text: 'Add Organization', def: 'add', type: 'add-btn' },
    ];

    constructor
    (
        private router: Router
    ) {
        // TODO add logic for true/false param
        this.columns = Organization.asDisplayColumns(true);
    }

    eventDetected(event: any) {
        if (event.action === 'add') {
            this.router.navigate(['/app/org-admin/add']);
        } else if (event.action === 'edit') {
            this.router.navigate([`/app/org-admin/${event.id}/edit`]);
        }
    }

    filterComparator = (item: Organization, value: string) => {
        if (item.emailAddress?.toLowerCase().includes(value)) {
            return true;
        }
        if (item.description.toLowerCase().includes(value)) {
            return true;
        }
        if (item.name?.toLowerCase().includes(value)) {
            return true;
        }
        if (Organization.getStatus(item).toLowerCase().includes(value)) {
            return true;
        }
        return false;
    };
}
