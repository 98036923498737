import { Component, ViewChild } from '@angular/core';
import { Business } from './models/business.model';
import { DisplayColumn } from 'src/app/modules/shared/models/display-column.model';
import { Action } from '../../components/action-table/models/action';
import { BasicActionTableComponent } from '../../components/action-table/basic-action-table/basic-action-table.component';

@Component({
    selector: 'app-business-admin',
    templateUrl: './business-admin.component.html',
    styleUrls: ['./business-admin.component.scss'],
})
export class BusinessAdminComponent {
    @ViewChild('app-basic-action-table') dataType: BasicActionTableComponent<Business>;
    columns: DisplayColumn[];
    actions: Action[] = [{ text: '', def: 'filter', type: 'filter' }];

    constructor() {
        this.columns = Business.asDisplayColumns(true);
    }

    getData() {}

    filterComparator = (item: Business, value: string) => {
        let isMatch = false;
        if (item.contactEmailAddress?.toLowerCase().includes(value)) {
            isMatch = true;
        } else if (item.description.toLowerCase().includes(value)) {
            isMatch = true;
        } else if (item.name?.toLowerCase().includes(value)) {
            isMatch = true;
        } else if (Business.getStatus(item).toLowerCase().includes(value)) {
            isMatch = true;
        }
        const startDt = new Date(item.contractStartDt);
        startDt.setHours(0, 0, 0, 0);
        const regex = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/;
        const isDateFormatStart = regex.test(value);
        if (isDateFormatStart) {
            const dateValue = new Date(value);
            if (startDt.getTime() === dateValue.getTime()) {
                isMatch = true;
            }
        }
        const endDt = new Date(item.contractEndDt);
        endDt.setHours(0, 0, 0, 0);
        const isDateFormatEnd = regex.test(value);
        if (isDateFormatEnd) {
            const dateValue = new Date(value);
            if (endDt.getTime() === dateValue.getTime()) {
                isMatch = true;
            }
        }
        return isMatch;
    };
}
