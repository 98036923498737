import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import { map, Observable } from 'rxjs';
import { AuthAbstractService } from '../services/auth/auth-abstract.service';

@Injectable()
export class AuthGuard implements CanLoad, CanActivate, CanActivateChild {
    constructor(
    private authSvc: AuthAbstractService,
    private router: Router,
    ) {}

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
        return this.checkAuthForRoute();
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> {
        return this.checkAuthForRoute();
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> {
        return this.checkAuthForRoute();
    }

    private checkAuthForRoute(): Observable<boolean | UrlTree> {
        return this.authSvc.checkAuthForRoute().pipe(
            map((isAllowed: boolean) => {
                if (isAllowed) return true;
                return this.router.createUrlTree(['/unauthorized']);
            }),
        );
    }
}
