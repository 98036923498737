import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDocumentCategory } from '../../models/document';

@Component({
    selector: 'app-document-category',
    styleUrls: ['./document-category.component.scss'],
    template: `
    <p-dropdown
      [options]="categories"
      [ngModel]="category"
      scrollHeight="300px"
      (onChange)="onValueChange($event.value)"
      placeholder=""
      appendTo="body"
      class="category-dropdown"
    >
      <ng-template pTemplate="header">
        <div class="p-3 dropdown-header">Move To:</div>
      </ng-template>
      <ng-template pTemplate="selectedItem">
        <button pTooltip="Move" class="btn">
          <i class="bi bi-folder-symlink"></i>
        </button>
      </ng-template>
      <ng-template let-category pTemplate="item">
        {{ category.name }}
      </ng-template>
    </p-dropdown>
  `,
})
export class DocumentCategoryComponent {
  @Input() categories: IDocumentCategory[];
  @Input() category: IDocumentCategory;
  @Output() selectionChange: EventEmitter<IDocumentCategory> =
      new EventEmitter<IDocumentCategory>();

  constructor() {}

  onValueChange(category: IDocumentCategory) {
      this.selectionChange.emit(category);
  }
}
