import { IDocumentCategory } from './document';
import { Property } from './property';

const MAX_NODE_LABEL_LENGTH = 30;

export function truncate(value: string, limit: number) {
    if (value?.length > limit) {
        return `${value.slice(0, limit)  }...`;
    }
    return value;
}

export interface ISelectionNodeData {
  categoryId: string;
  categoryName: string;
  propertyId: string;
  propertyName: string;
}

export interface ICopyRequest {
  fileInfoIdToCopy: string;
  categoryId: string;
  propertyId: string;
}

export class SelectionNode {
    key: string;
    label: string;
    data?: ISelectionNodeData;
    expandedIcon: string;
    collapsedIcon: string;
    selectable: boolean;
    children: SelectionNode[] = [];

    constructor(property: Property, categories: IDocumentCategory[]) {
        this.key = property.id;
        this.label = property.name;
        this.data = null;
        this.expandedIcon = 'pi pi-building';
        this.collapsedIcon = this.expandedIcon;
        this.selectable = false;

        for (let i = 0; i < categories.length; i++) {
            const node: SelectionNode = {
                key: property.id + categories[i].id,
                label: truncate(categories[i].name, MAX_NODE_LABEL_LENGTH),
                data: {
                    categoryId: categories[i].id,
                    categoryName: categories[i].name,
                    propertyId: property.id,
                    propertyName: property.name,
                },
                expandedIcon: 'pi pi-folder',
                collapsedIcon: 'pi pi-folder',
                selectable: true,
                children: [],
            };
            this.children.push(node);
        }
    }
}
