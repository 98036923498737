import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IApiDataResponse } from '../../../models/api-response.interface';

@Injectable({
    providedIn: 'root',
})
export class BasicActionTableService<T> {
    private _dataSource: BehaviorSubject<T[] | null> =
        new BehaviorSubject<T[] | null>(null);

    get dataSourceSnapshot(): T[] | null {
        return this._dataSource.value;
    }

    get dataSource$(): Observable<T[] | null> {
        return this._dataSource.asObservable();
    }
    
    constructor(private _http: HttpClient) {}

    get(endpoint: string): Observable<T[] | void> {
        if (endpoint === 'businessadmin') {
            return this._http.get<IApiDataResponse<T[]>>(`${environment.apiBaseURL}${endpoint}`).pipe(
                map((response: IApiDataResponse<T[]>) => {
                    this._dataSource.next(response.data);
                })
            );
        }
        if (endpoint === 'organization') {
            return this._http.get<IApiDataResponse<T[]>>(`${environment.apiBaseURL}${endpoint}`).pipe(
                map((response: IApiDataResponse<T[]>) => {
                    this._dataSource.next(response.data);
                })
            );
        }
        return null;
    }

    suspend(endpoint: string, id: number): Observable<T[] | void> {
        return this._http.post<IApiDataResponse<T[]>>(`${environment.apiBaseURL}${endpoint}/Suspend?id=${id}`, {}).pipe(
            map((response: IApiDataResponse<T[]>) => {
                this._dataSource.next(response.data);
            })
        );
    }

    delete(endpoint: string, id: number): Observable<T[] | void> {
        return this._http.post<IApiDataResponse<T[]>>(`${environment.apiBaseURL}${endpoint}/Delete?id=${id}`, {}).pipe(
            map((response: IApiDataResponse<T[]>) => {
                this._dataSource.next(response.data);
            })
        );
    }

    TeamMembersByOrganization(endpoint: string, id: number): Observable<T[] | void> {
        return this._http.get<IApiDataResponse<T[]>>(`${environment.apiBaseURL}${endpoint}/ByOrganization?organizationId=${id}`, {}).pipe(
            map((response: IApiDataResponse<T[]>) => {
                this._dataSource.next(response.data);
            })
        );
    }
}
