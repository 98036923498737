<p-fileUpload
  name="filePicker"
  mode="advanced"
  cancelLabel="Clear"
  [files]="incomingFiles"
  [fileLimit]="1"
  [customUpload]="true"
  [multiple]="false"
  [showUploadButton]="false"
  accept=".xlsx, .csv, .xls"
  (onSelect)="selectFiles($event.files)"
  (onClear)="clearSelectedFiles()"
>
  <ng-template let-file pTemplate="file"></ng-template>
  <ng-template pTemplate="content" let-files>
    <div
      style="max-height: 45vh; overflow-y: auto; overflow-x: hidden"
      class="w-100"
    >
      <div *ngFor="let file of files" class="d-flex flex-column">
        {{ file.name }}
        <span class="p-float-label mt-4">
          <p-dropdown
            id="sheetName"
            [options]="currentSheetNames"
            [(ngModel)]="selectedSheetName"
            appendTo="body"
          ></p-dropdown>
          <label for="sheetName">Sheet</label>
        </span>
        <span *ngIf="showHeaderRowInput" class="p-float-label mt-4">
          <input
            pInputText
            id="headerRowNum"
            class="form-control mapper-short-input"
            type="number"
            [(ngModel)]="headerRowIndex"
            [min]="1"
          />
          <label for="headerRowNum">Header Row Number</label>
        </span>
      </div>
    </div>
    <div *ngIf="!currentFile">
      <i
        class="pi pi-upload d-flex justify-content-center"
        style="font-size: 2rem; color: lightgray"
      ></i>
      <span class="d-flex justify-content-center"
        >Choose files or drag them here.</span
      >
    </div>
  </ng-template>
</p-fileUpload>
<div class="d-flex justify-content-end">
  <button
    class="btn btn-primary mt-2"
    (click)="close()"
    [disabled]="!currentFile"
  >
    Continue
  </button>
</div>
