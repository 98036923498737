<div class="d-flex flex-row justify-content-center align-item-center">
  <div class="card shadow-sm" style="width: 600px">
    <div class="card-header">
      <ng-content select="[title]"></ng-content>
    </div>
    <div class="card-body">
      <div class="d-flex flex-column justify-content-start align-items-center">
        <form
          [formGroup]="form"
          style="width: 100%"
          (ngSubmit)="onSubmitUser()"
        >
          <div class="d-flex flex-row align-items-bottom">
            <label for="profilePic" class="col-form-label flex-grow-1"
              >Profile Picture:
              <p-fileUpload
                name="image"
                [customUpload]="true"
                (onSelect)="imageUploader($event)"
                [multiple]="false"
                [showUploadButton]="false"
              ></p-fileUpload>
            </label>
          </div>
          <div class="d-flex flex-row align-items-bottom">
            <label for="firstName" class="col-form-label flex-grow-1"
              >First Name:
              <input
                type="text"
                id="firstName"
                [ngClass]="{
                  'form-control': true,
                  'is-invalid':
                    form.controls['firstName'].invalid &&
                    (form.controls['firstName'].dirty ||
                      form.controls['firstName'].touched)
                }"
                formControlName="firstName"
              />
              <ng-container
                *ngIf="
                  form.controls['firstName'].invalid &&
                  (form.controls['firstName'].dirty ||
                    form.controls['firstName'].touched)
                "
              >
                <div
                  class="invalid-feedback"
                  *ngIf="form.controls['firstName'].errors?.['required']"
                >
                  Please enter a first name.
                </div>
              </ng-container>
            </label>
          </div>
          <div class="d-flex flex-row align-items-bottom">
            <label for="lastName" class="col-form-label flex-grow-1"
              >Last Name:
              <input
                type="text"
                id="lastName"
                [ngClass]="{
                  'form-control': true,
                  'is-invalid':
                    form.controls['lastName'].invalid &&
                    (form.controls['lastName'].dirty ||
                      form.controls['lastName'].touched)
                }"
                formControlName="lastName"
              />
              <ng-container
                *ngIf="
                  form.controls['lastName'].invalid &&
                  (form.controls['lastName'].dirty ||
                    form.controls['lastName'].touched)
                "
              >
                <div
                  class="invalid-feedback"
                  *ngIf="form.controls['lastName'].errors?.['required']"
                >
                  Please enter a last name.
                </div>
              </ng-container>
            </label>
          </div>
          <div class="d-flex flex-row align-items-bottom">
            <label for="userName" class="col-form-label flex-grow-1"
              >Username:
              <input
                type="email"
                id="userName"
                formControlName="userName"
                [ngClass]="{
                  'form-control': true,
                  'is-invalid':
                    form.controls['userName'].invalid &&
                    (form.controls['userName'].dirty ||
                      form.controls['userName'].touched)
                }"
              />
              <ng-container
                *ngIf="
                  form.controls['userName'].invalid &&
                  (form.controls['userName'].dirty ||
                    form.controls['userName'].touched)
                "
              >
                <div
                  class="invalid-feedback"
                  *ngIf="form.controls['userName'].errors?.['required']"
                >
                  Please enter a username.
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="form.controls['userName'].errors?.['email']"
                >
                  Please enter a valid email.
                </div>
              </ng-container>
            </label>
          </div>
          <div class="d-flex flex-row align-items-bottom">
            <label for="phoneNumber" class="col-form-label flex-grow-1 p-fluid"
              >Phone Number:
              <p-inputMask
                mask="(999) 999-9999"
                placeholder="(999) 999-9999"
                formControlName="phoneNumber"
                [ngClass]="{
                  'is-invalid': form.controls['phoneNumber'].invalid
                }"
              >
              </p-inputMask>
              <ng-container *ngIf="form.controls['phoneNumber'].invalid">
                <div
                  class="invalid-feedback"
                  *ngIf="form.controls['phoneNumber'].errors?.['required']"
                >
                  Phone Number required for Two-Factor Auth.
                </div>
              </ng-container>
            </label>
            <br />
          </div>
          <div class="row my-2 align-items-center">
            <label class=""> Two-factor Authentication: </label>
          </div>
          <div class="d-flex">
            <div class="col form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="twoFactorEnabled"
                [checked]="user?.twoFactorEnabled"
                (click)="toggle2FA()"
              />
              <div class="d-none d-sm-block">
                <ng-container *ngIf="form.controls['twoFactorEnabled'].value">
                  <label class="form-check-label" for="twoFactorEnabled"
                    >Enabled</label
                  >
                </ng-container>
                <ng-container *ngIf="!form.controls['twoFactorEnabled'].value">
                  <label class="form-check-label" for="twoFactorEnabled"
                    >Disabled</label
                  >
                </ng-container>
              </div>
            </div>
            <fieldset
              class="col"
              [disabled]="!form.controls['twoFactorEnabled'].value"
            >
              <select class="form-select" formControlName="twoFactorType">
                <option value="email">Email</option>
                <option value="text">Text</option>
              </select>
            </fieldset>
          </div>
          <div class="row my-2">
            <label class=""> Email Notifications: </label>
          </div>
          <div class="d-flex">
            <div class="col form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="notificationsEnabled"
                formControlName="notificationsEnabled"
              />
              <div class="d-none d-sm-block">
                <ng-container
                  *ngIf="form.controls['notificationsEnabled'].value"
                >
                  <label class="form-check-label" for="notificationsEnabled"
                    >Enabled</label
                  >
                </ng-container>
                <ng-container
                  *ngIf="!form.controls['notificationsEnabled'].value"
                >
                  <label class="form-check-label" for="notificationsEnabled"
                    >Disabled</label
                  >
                </ng-container>
              </div>
            </div>
          </div>

          <ng-content select="[roleManagement]"></ng-content>
          <div class="d-flex flex-row-reverse mt-2">
            <ng-content select="[submitButton]"></ng-content>
            <ng-content select="[cancelButton]"></ng-content>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
