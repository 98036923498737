import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, tap } from 'rxjs';
import { AuthAbstractService } from '../../../core/services/auth/auth-abstract.service';

@Component({
    selector: 'app-user-invitation',
    templateUrl: './user-invitation.component.html',
    styleUrls: ['./user-invitation.component.scss'],
})
export class UserInvitationComponent implements OnInit {
    public token: string;
    public userName: string;
    public submitting: boolean = false;

    constructor(
    private authSvc: AuthAbstractService,
    private route: ActivatedRoute,
    private router: Router,
    ) {}

    ngOnInit(): void {
        this.token = this.route.snapshot.queryParams['token'];
        this.userName = this.route.snapshot.queryParams['username'];
    }

    public onSubmit(password: string): void {
        const createPasswordRqt = {
            token: this.token,
            username: this.userName,
            password: password,
        };

        this.submitting = true;
        this.authSvc
            .register(createPasswordRqt)
            .pipe(
                tap(() => {
                    // Clear any tokens / user information if another user is currently logged in.
                    this.authSvc.logout().subscribe();
                }),
                finalize(() => { this.submitting = false; }),
            )
            .subscribe();
    }
}
