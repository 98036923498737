const API_BASE_URL = 'https://goodneighborapi-dev.flowarle.com/api/';

export const environment = {
    production: false,
    apiBaseURL: API_BASE_URL,
    azureADAuthProvider: {
        clientId: 'aef43610-37f9-47ae-a778-33a7d50d06eb',
        tenantId: '7203a756-9f21-4bea-b8cb-17067c885a93',
    },
    googleClientId: '<google-client-id>',
    region: 'development'
}
