<app-action-table #actionTable *ngIf="dataSource || organizations"
  [dataName]="dataName"
  [columns]="columns"
  [dataSource]="dataSource"
  [actions]="actions"
  [organizations]="organizations"
  [filterComparator]="filterComparator"
  (actionEvent)="eventDetected($event)"
></app-action-table>


<p-dialog header="header" [modal]="true" [(visible)]="dialogVisible" [style]="{ width: '25rem' }">
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-center gap-2">
      <span class="font-bold white-space-nowrap">
        Delete Confirmation
      </span>
    </div>
  </ng-template>
  <span class="mb-5">Are you sure you want to delete the entry?</span>
  <div class="flex justify-content-end gap-2">
      <button
          type="button"
          class="btn btn-secondary me-0 me-md-2"
          (click)="dialogVisible = false"
      >
          Cancel
      </button>
      <button
          type="submit"
          class="btn btn-primary ms-0 ms-2 mt-md-0"
          [disabled]="submitting"
          (click)="submitDeletion()"
      >
          <ng-container *ngIf="submitting; else default">
          <div class="spinner-border spinner-border-sm" role="status"></div>
          Saving...
          </ng-container>

          <ng-template #default>Delete</ng-template>
      </button>
  </div>
</p-dialog>