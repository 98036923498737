<div class="header">
  <!-- TODO this will need to be search bar or drop down*** -->
  <ng-container *ngFor="let act of actions">
    <div class="left-side-btns">
      <input
        *ngIf="act.type === 'filter'"
        type="text"
        placeholder="Search by keyword"
        [ngModel]="searchField"
        (ngModelChange)="dataSource.filter($event, filterComparator)"
      />

      <i
        *ngIf="act.type === 'filter'"
        class="bi bi-search"
        style="margin-left: -25px"
      ></i>

      <button
        *ngIf="act.type === 'add-btn'"
        class="btn btn-primary"
        (click)="raiseEvent(act.def, null)"
      >
        {{ act.text }}
      </button>
      <p-dropdown
        *ngIf="act.type === 'dropdown'"
        [options]="organizations"
        [(ngModel)]="selectedOrganization"
        (onChange)="onOrganizationSelection($event, act.def)"
        optionLabel="name"
        optionValue="id"
        placeholder="Select Organization"
      ></p-dropdown>
    </div>
  </ng-container>
</div>
<table class="table" cdk-table [dataSource]="dataOnPage">
  <ng-container *ngFor="let col of columns">
    <!-- text (default) -->
    <ng-container *ngIf="!col.type || col.type === 'text'">
      <ng-container [cdkColumnDef]="col.def">
        <th cdk-header-cell *cdkHeaderCellDef>{{ col.title }}</th>
        <td cdk-cell *cdkCellDef="let element">{{ element[col.def] }}</td>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="col.type === 'button'">
      <ng-container [cdkColumnDef]="col.def">
        <th cdk-header-cell *cdkHeaderCellDef>{{ col.title }}</th>
        <td cdk-cell *cdkCellDef="let element">
          <button
            (click)="raiseEvent(col.def, element.id)"
          > 
            <ng-container *ngIf="col.text !== 'Suspend' || element.suspendedDt == null">
              {{ col.text }}
            </ng-container>
            <ng-container *ngIf="col.text == 'Suspend' && element.suspendedDt">
              Reactivate
            </ng-container>
          </button>
        </td>
      </ng-container>
    </ng-container>

    <!-- date range -->
    <ng-container *ngIf="col.type === 'date-range'">
      <ng-container [cdkColumnDef]="col.def">
        <th cdk-header-cell *cdkHeaderCellDef>{{ col.title }}</th>
        <td cdk-cell *cdkCellDef="let element" style="min-width: 12rem;">
          <!-- If date format is adjusted. Please adjust the filter comparator in business component -->
          {{ element.contractStartDt | date: 'MM/dd/yy' }} -                
          {{ element.contractEndDt | date: 'MM/dd/yy' }}
        </td>
      </ng-container>
    </ng-container>

    <!-- status (calculated) -->
    <ng-container *ngIf="col.type === 'status'">
      <ng-container [cdkColumnDef]="col.def">
        <th cdk-header-cell *cdkHeaderCellDef>{{ col.title }}</th>
        <td cdk-cell *cdkCellDef="let element">
          {{ calcStatus(element) }}
        </td>
      </ng-container>
    </ng-container>
  </ng-container>

  <tr cdk-header-row *cdkHeaderRowDef="colDefs"></tr>
  <tr cdk-row *cdkRowDef="let row; columns: colDefs"></tr>
</table>
<div class="d-flex justify-content-end">
  <p-paginator
    (onPageChange)="onPageChange($event)"
    [first]="first"
    [rows]="rows"
    [totalRecords]="dataSource.length()"
    *ngIf="dataOnPage"
  >
  </p-paginator>
</div>

<div class="container" *ngIf="dataOnPage == null">
  <p>Choose an organization from the dropdown to view and manage team members</p>
</div>
