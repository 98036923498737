import { Component, OnInit } from '@angular/core';
import { filter, Observable, Subject, tap } from 'rxjs';
import { AuthAbstractService } from 'src/app/modules/core/services/auth/auth-abstract.service';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { User } from 'src/app/modules/core/models/user';
import { UserManagementService } from 'src/app/modules/admin/users/services/user-management/user-management.service';
import { UserRole } from 'src/app/modules/shared/statuses';
import { MenuItem } from '../models/menu-item.model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-app-layout',
    templateUrl: './app-layout.component.html',
    styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent implements OnInit {
    public currentDate: Date = new Date();
    public user$: Observable<User | null>;
    public isAdmin: boolean = false;
    public impersonationModal: boolean = false;
    userList: User[];
    userId: string = null;
    selectedUser: User;
    public impersonating: boolean = false;
    public externalLogin: boolean = false;
    private readonly _destroying$ = new Subject<void>();
    public collapsedMenu = false;

    public menuItems: MenuItem[] = [
        {
            url: '/app/dashboard',
            name: 'Dashboard',
            icon: 'pi pi-home',
        },
        {
            url: '/app/business-admin',
            name: 'Business Admin',
            icon: 'pi pi-shopping-bag',
        },
        {
            url: '/app/org-admin',
            name: 'Organization Admin',
            icon: 'pi pi-heart',
        },
        {
            url: '/app/team-member',
            name: 'Team Members',
            icon: 'pi pi-users',
        },
        {
            url: '/app/admin/users',
            name: 'User Management',
            icon: 'pi pi-user',
        },
    ];

    constructor(
    private authSvc: AuthAbstractService,
    private userSvc: UserService,
    private userMgmtService: UserManagementService,
    private router: Router,
    ) {}

    ngOnInit(): void {
        this.user$ = this.userSvc.user$;
        this.user$.pipe(filter((user) => user !== null)).subscribe((user) => {
            this.userId = user.id;
            user?.roles.forEach((role) => {
                if (role === UserRole.Admin) this.isAdmin = true;
            });
        });
        this.impersonating = this.authSvc.isImpersonating();
        if (this.isAdmin) {
            this.loadUsers();
        }
    }
    toggleMenu() {
        this.collapsedMenu = !this.collapsedMenu;
    }

    loadUsers() {
        this.userMgmtService
            .getUsers()
            .pipe(
                tap((result) => {
                    this.userList = result.data.filter((u) => u.id !== this.userId);
                }),
            )
            .subscribe();
    }

    logout() {
        this.authSvc.logout(true).subscribe();
    }

    checkRouteActive(item: MenuItem): boolean {
        return this.router.url === item.url;
    }

    impersonateClicked() {
        this.impersonationModal = true;
    }

    clearClicked() {
        this.userId = null;
    }

    userSelected(event: any) {
        if (event.value === null) {
            return;
        }
        const user = this.userList.find((x) => x.id === event.value.id);
        this.selectedUser = user;
    }

    cancelClicked() {
        this.impersonationModal = false;
        this.userId = null;
    }

    confirmClicked() {
        this.authSvc.impersonate(this.selectedUser.id);
        this.impersonationModal = false;
        this.userId = null;
    }

    stopImpersonatingClicked() {
        this.authSvc.stopImpersonating();
    }
}
