import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IApiDataResponse } from 'src/app/modules/core/models/api-response.interface';
import { IRole, Role } from 'src/app/modules/core/models/role';
import { User } from 'src/app/modules/core/models/user';
import { RoleApiService } from 'src/app/modules/core/services/role-api/role-api.service';

@Component({
    selector: 'app-user-role-management',
    templateUrl: './user-role-management.component.html',
    styleUrls: ['./user-role-management.component.scss'],
})
export class UserRoleManagementComponent implements OnInit {
  @Input() user: User;
  @Output() rolesChanged: EventEmitter<string[]> = new EventEmitter<string[]>();
  selectedRole: string;
  roles: Role[] = [];

  constructor(private roleApiSvc: RoleApiService) {}

  ngOnInit(): void {
      this.roleApiSvc
          .getRoles()
          .subscribe((response: IApiDataResponse<IRole[]>) => {
              this.roles = response.data;
              if (this.user?.roles) {
                  this.selectedRole = this.user.roles.find((u) =>
                      this.roles.some((r) => r.name === u),
                  );
                  this.rolesChanged.emit([this.selectedRole]);
              }
          });
  }

  public onRoleSelect(roleName: string) {
      this.selectedRole = roleName;
      this.rolesChanged.emit([this.selectedRole]);
  }
}
