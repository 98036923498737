<div *ngIf="impersonating" class="row impersonating"></div>

<nav class="mobile-navbar navbar navbar-dark shadow-sm px-2">
  <a class="navbar-brand">SteeleFrame</a>
  <button
    class="navbar-toggler"
    type="button"
    data-bs-toggle="offcanvas"
    data-bs-target="#offcanvasNavbar"
    data-bs-keyboard="true"
    aria-controls="offcanvasNavbar"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div
    class="offcanvas offcanvas-start d-lg-none"
    tabindex="-1"
    id="offcanvasNavbar"
    data-bs-scroll="false"
    aria-labelledby="offcanvasNavbarLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasNavbarLabel">SteeleFrame</h5>
      <button
        type="button"
        class="btn-close btn-close-white"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div
      class="offcanvas-body d-flex justify-content-between flex-column"
      scroll="no"
    >
      <ul class="nav flex-column w-100">
        <ng-container *ngFor="let item of menuItems">
          <li
            class="text-center"
            style="display: flex; align-items: center"
            [class.nav-active]="checkRouteActive(item)"
          >
            <ng-container *ngIf="!item.adminOnly || isAdmin">
              <a
                [routerLink]="item.url"
                class="nav-link"
                data-bs-dismiss="offcanvas"
                ><i [class]="item.icon" class="me-2" style="height: 30px"></i
                >{{ item.name }}</a
              >
            </ng-container>
          </li>
        </ng-container>
      </ul>
      <ul class="nav flex-column w-100">
        <div class="accordion">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="profile-btn btn menu-item-color"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <i class="pi pi-cog me-2"></i>{{ 'Account' }}
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse">
              <div class="accordion-body">
                <li>
                  <div class="dropdown-item">
                    {{ (user$ | async)?.userName }}
                  </div>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <div
                    class="dropdown-item"
                    routerLink="/app/user/profile"
                    data-bs-dismiss="offcanvas"
                  >
                    View Profile
                  </div>
                </li>
                <li *ngIf="!externalLogin">
                  <div
                    class="dropdown-item"
                    routerLink="/app/user/change-password"
                    data-bs-dismiss="offcanvas"
                  >
                    Change Password
                  </div>
                </li>
                <li></li>
                <li>
                  <div *ngIf="isAdmin || impersonating">
                    <div
                      *ngIf="!impersonating"
                      class="dropdown-item"
                      (click)="impersonateClicked()"
                    >
                      Impersonate
                    </div>
                    <div
                      *ngIf="impersonating"
                      class="dropdown-item"
                      (click)="stopImpersonatingClicked()"
                    >
                      Stop Impersonating
                    </div>
                  </div>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <div class="dropdown-item" (click)="logout()">Sign Out</div>
                </li>
              </div>
            </div>
          </div>
        </div>
      </ul>
    </div>
  </div>
</nav>

<div class="desktop-navbar">
  <div
    class="side-navbar d-flex justify-content-between flex-wrap flex-column add-shadow"
    [ngClass]="collapsedMenu ? 'collapsed-menu-nav' : 'expanded-menu-nav'"
  >
    <ul class="nav flex-column w-100">
      <div class="py-3 d-flex justify-content-center">
        <img
          *ngIf="!collapsedMenu"
          class="image"
          src="assets/flowar-logo.png"
        />
      </div>
      <li
        *ngFor="let item of menuItems"
        [ngClass]="collapsedMenu ? 'text-center' : ''"
        style="display: flex; align-items: center"
        [class.nav-active]="checkRouteActive(item)"
      >
        <ng-container *ngIf="!item.adminOnly || isAdmin">
          <a [routerLink]="item.url" *ngIf="!collapsedMenu" class="nav-link align-items-center" style="height: 3rem;"
            ><i [class]="item.icon" class="me-2"></i
            >{{ item.name }}</a
          >
          <a [routerLink]="item.url" *ngIf="collapsedMenu" class="nav-link align-items-center"
            ><i
              [class]="item.icon"
              class="me-2"
              style="height: 30px; margin-left: 140px"
            ></i
          ></a>
        </ng-container>
      </li>
    </ul>
    <ul class="nav flex-column w-100">
      <li>
        <div class="nav-link">
          <div class="dropdown">
            <button
              class="btn menu-item-color p-0 profile-btn"
              id="navbarDropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style="display: flex; align-items: center"
            >
              <i
                class="pi pi-cog me-2"
                [ngStyle]="{ 'margin-left': collapsedMenu ? '140px' : '0' }"
              ></i
              >{{ collapsedMenu ? '' : 'Account' }}
            </button>
            <div
              class="dropdown-menu dropdown-menu-end shadow-sm"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <li>
                <div class="dropdown-item">
                  {{ (user$ | async)?.userName }}
                </div>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <div class="dropdown-item" routerLink="/app/user/profile">
                  View Profile
                </div>
              </li>
              <li *ngIf="!externalLogin">
                <div
                  class="dropdown-item"
                  routerLink="/app/user/change-password"
                >
                  Change Password
                </div>
              </li>
              <li></li>
              <li>
                <div *ngIf="isAdmin || impersonating">
                  <div
                    *ngIf="!impersonating"
                    class="dropdown-item"
                    (click)="impersonateClicked()"
                  >
                    Impersonate
                  </div>
                  <div
                    *ngIf="impersonating"
                    class="dropdown-item"
                    (click)="stopImpersonatingClicked()"
                  >
                    Stop Impersonating
                  </div>
                </div>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <div class="dropdown-item" (click)="logout()">Sign Out</div>
              </li>
            </div>
          </div>
        </div>
      </li>
      <li
        class="nav-item"
        style="display: flex; justify-content: center; cursor: pointer"
      >
        <a (click)="toggleMenu()" *ngIf="!collapsedMenu" class="nav-link"
          ><i class="pi pi-angle-double-left menu-item-color me-2"></i
          >Collapse</a
        >
        <a (click)="toggleMenu()" *ngIf="collapsedMenu" class="nav-link"
          ><i
            class="pi pi-angle-double-right menu-item-color"
            style="margin-left: 140px"
          ></i
        ></a>
      </li>
    </ul>
  </div>
</div>

<div
  class="p-4 my-container"
  [ngClass]="collapsedMenu ? 'collapsed-menu-cont' : 'expanded-menu-cont'"
>
  <router-outlet></router-outlet>
</div>

<p-dialog
  header="Impersonate"
  [(visible)]="impersonationModal"
  [modal]="true"
  [style]="{ width: '400px' }"
  [baseZIndex]="5000"
  [draggable]="false"
  [resizable]="false"
>
  <ng-template let-message pTemplate="message">
    <label for="userDropdown">Select User:</label>
    <div class="d-flex flex-row">
      <p-dropdown
        appendTo="body"
        [options]="userList"
        placeholder="Username..."
        optionLabel="userName"
        [(ngModel)]="userId"
        id="userDropdown"
        [showClear]="true"
        (onChange)="userSelected($event)"
        [style]="{ width: '300px' }"
        (onClear)="clearClicked()"
        [filter]="true"
        filterBy="userName"
      ></p-dropdown>
    </div>
  </ng-template>

  <ng-template let-message pTemplate="footer">
    <button
      pButton
      type="button"
      (click)="cancelClicked()"
      label="Cancel"
      class="p-button-secondary p-button-text"
    ></button>
    <button
      pButton
      type="button"
      (click)="confirmClicked()"
      label="Select"
      class="p-button-primary p-button-text"
    ></button>
  </ng-template>
</p-dialog>
